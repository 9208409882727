import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { appendIndex, removeIndex, threads } from '../actions/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Schedule from '../components/Schedule';
import SnipingCount from '../components/SnipingCount';
import { motion } from 'framer-motion';
import { FiSearch, FiPlus, FiActivity, FiServer, FiPhone } from 'react-icons/fi';

const Dashboard = ({ SniperIndexes, appendIndex, removeIndex, threads, userName, CalendarIndexes, year, phone }) => {
  console.log('Year value:', year);
  const [courseSearchValue, setCourseSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [threadsData, setThreadsData] = useState(null);
  const [courseNumbers, setCourseNumbers] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchThreads = async () => {
      const data = await threads();
      setThreadsData(data-2);
    };
    fetchThreads();
  }, [threads]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (courseSearchValue.trim() === '') {
        setSuggestions([]);
        return;
      }
      try {
        const response = await axios.get(
          `https://api.ruturbo.com/autocomplete?title=${encodeURIComponent(courseSearchValue)}`
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
    fetchSuggestions();
  }, [courseSearchValue]);

  const handleCourseSearchChange = (e) => {
    setCourseSearchValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setCourseSearchValue(suggestion);
    setSuggestions([]);
    navigate(`/search?title=${encodeURIComponent(suggestion)}`);
  };

  const handleCourseSearchSubmit = (e) => {
    e.preventDefault();
    if (courseSearchValue.trim() === '') return;
    navigate(`/search?title=${encodeURIComponent(courseSearchValue)}`);
  };

  const handleCourseNumbersChange = (e) => {
    setCourseNumbers(e.target.value);
  };

  const handleCourseNumbersSubmit = async (e) => {
    e.preventDefault();
    if (!courseNumbers.trim()) return;

    const numbers = courseNumbers
      .split(',')
      .map(num => num.trim())
      .filter(num => num);

    if (numbers.length > 0) {
      await appendIndex(numbers);
      setCourseNumbers('');
    }
  };

  // Calculate course stats
  const totalCourses = CalendarIndexes?.length || 0;
  const maxCourses = 10; // Maximum recommended courses
  const coursePercentage = (totalCourses / maxCourses) * 100;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 p-6 md:p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-[1600px] mx-auto space-y-8"
      >
        {/* Welcome Header Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-center space-y-4"
        >
          <h1 className="text-4xl md:text-5xl font-bold">
            <span className="text-gray-200">Welcome back, </span>
            <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 
              text-transparent bg-clip-text">
              {userName || 'User'}
            </span>
          </h1>
          <p className="text-gray-400 text-lg">
            Manage your courses and track your snipes
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Course Schedule - Now with course counter */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="lg:col-span-8 order-2 lg:order-1"
          >
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-xl 
              border border-gray-700/50"
            >
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold bg-gradient-to-r from-indigo-400 to-blue-500 
                  text-transparent bg-clip-text">
                  Course Schedule
                </h2>
                
                {/* Course Counter */}
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  className="flex items-center space-x-4"
                >
                  <div className="flex items-center space-x-2">
                    <div className="relative h-2 w-24 bg-gray-700 rounded-full overflow-hidden">
                      <motion.div
                        initial={{ width: 0 }}
                        animate={{ width: `${coursePercentage}%` }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        className={`absolute left-0 top-0 h-full rounded-full ${
                          coursePercentage >= 85 
                            ? 'bg-gradient-to-r from-red-500 to-red-600' 
                            : coursePercentage >= 50 
                              ? 'bg-gradient-to-r from-yellow-500 to-yellow-600'
                              : 'bg-gradient-to-r from-green-500 to-green-600'
                        }`}
                      />
                    </div>
                    <div className="flex items-baseline">
                      <span className="text-2xl font-bold text-white">{totalCourses}</span>
                      <span className="text-sm text-gray-400 ml-1">/ {maxCourses}</span>
                    </div>
                  </div>
                  <div className={`text-xs font-medium px-2 py-1 rounded-full ${
                    coursePercentage >= 85 
                      ? 'bg-red-500/20 text-red-400' 
                      : coursePercentage >= 50 
                        ? 'bg-yellow-500/20 text-yellow-400'
                        : 'bg-green-500/20 text-green-400'
                  }`}>
                    { coursePercentage == 100 ? 'Full':
                    coursePercentage >= 85 ? 'Heavy Load' : 
                     coursePercentage >= 50 ? 'Moderate Load' : 
                     'Light Load'}
                  </div>
                </motion.div>
              </div>

              <div className="overflow-x-auto">
                <div className="min-w-[800px]">
                  <Schedule />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right Sidebar - Now spans 4 columns */}
          <div className="lg:col-span-4 space-y-6 order-1 lg:order-2">
            {/* Year Information Prompt */}
            {year === 'N/A' && (
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="bg-gradient-to-br from-yellow-800/50 to-yellow-900/50 rounded-2xl p-6 shadow-xl 
                  border border-yellow-700/50"
              >
                <div className="flex items-center gap-3 mb-4">
                  <FiActivity className="w-6 h-6 text-yellow-400" />
                  <h2 className="text-xl font-semibold bg-gradient-to-r from-yellow-400 to-yellow-500 
                    text-transparent bg-clip-text">
                    Complete Your Profile
                  </h2>
                </div>
                <div className="space-y-4">
                  <p className="text-yellow-300 text-sm">
                    We noticed that your academic year is not set. Please update your profile to 
                    get personalized course recommendations and features.
                  </p>
                  <button
                    onClick={() => navigate('/profile')}
                    className="w-full bg-gradient-to-r from-yellow-500 to-yellow-600 
                      hover:from-yellow-600 hover:to-yellow-700 text-white 
                      py-3 rounded-xl transition-all duration-300 
                      flex items-center justify-center space-x-2"
                  >
                    <FiPlus className="w-5 h-5" />
                    <span>Update Year</span>
                  </button>
                </div>
              </motion.div>
            )}

            {phone === 'N/A' && (
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                className="bg-gradient-to-br from-orange-800/50 to-orange-900/50 rounded-2xl p-6 shadow-xl 
                  border border-orange-700/50"
              >
                <div className="flex items-center gap-3 mb-4">
                  <FiPhone className="w-6 h-6 text-orange-400" />
                  <h2 className="text-xl font-semibold bg-gradient-to-r from-orange-400 to-orange-500 
                    text-transparent bg-clip-text">
                    Add Phone Number
                  </h2>
                </div>
                <div className="space-y-4">
                  <p className="text-orange-300 text-sm">
                    We noticed that your phone number is not set. Please update your profile to 
                    enable phone notifications.
                  </p>
                  <button
                    onClick={() => navigate('/profile')}
                    className="w-full bg-gradient-to-r from-orange-500 to-orange-600 
                      hover:from-orange-600 hover:to-orange-700 text-white 
                      py-3 rounded-xl transition-all duration-300 
                      flex items-center justify-center space-x-2"
                  >
                    <FiPlus className="w-5 h-5" />
                    <span>Add Phone Number</span>
                  </button>
                </div>
              </motion.div>
            )}

            {/* Search Courses Card */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-xl 
                border border-gray-700/50"
            >
              <div className="flex items-center gap-3 mb-4">
                <FiSearch className="w-6 h-6 text-blue-400" />
                <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-400 to-blue-500 
                  text-transparent bg-clip-text">
                  Search Courses
                </h2>
              </div>
              <form onSubmit={handleCourseSearchSubmit} className="space-y-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search for courses..."
                    value={courseSearchValue}
                    onChange={handleCourseSearchChange}
                    className="w-full p-3 rounded-xl bg-gray-700/50 text-white border border-gray-600 
                      focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 focus:outline-none 
                      transition-all duration-200"
                  />
                  <button type="submit" className="absolute right-3 top-3 text-gray-400 
                    hover:text-blue-400 transition-colors">
                    <FiSearch className="w-5 h-5" />
                  </button>
                </div>
                {suggestions.length > 0 && (
                  <ul className="mt-2 bg-gray-700/50 rounded-xl overflow-hidden max-h-48 
                    overflow-y-auto custom-scrollbar border border-gray-600">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="px-4 py-2 hover:bg-gray-600/50 cursor-pointer text-white 
                          text-sm transition-colors"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </form>
            </motion.div>

            {/* Add Course Numbers Card */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-xl 
                border border-gray-700/50"
            >
              <div className="flex items-center gap-3 mb-4">
                <FiPlus className="w-6 h-6 text-green-400" />
                <h2 className="text-xl font-semibold bg-gradient-to-r from-green-400 to-emerald-500 
                  text-transparent bg-clip-text">
                  Add Course Numbers
                </h2>
              </div>
              <form onSubmit={handleCourseNumbersSubmit} className="space-y-4">
                <input
                  type="text"
                  placeholder="Enter course numbers (comma-separated)"
                  value={courseNumbers}
                  onChange={handleCourseNumbersChange}
                  className="w-full p-3 rounded-xl bg-gray-700/50 text-white border border-gray-600 
                    focus:border-green-500 focus:ring-2 focus:ring-green-500/20 focus:outline-none 
                    transition-all duration-200"
                />
                <button type="submit" className="w-full bg-gradient-to-r from-green-500 to-emerald-600 
                  text-white py-3 rounded-xl font-medium hover:from-green-600 hover:to-emerald-700 
                  transition-all duration-200 shadow-lg hover:shadow-green-500/20">
                  Add Numbers
                </button>
              </form>
            </motion.div>

            {/* Active Snipes Card */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-xl 
                border border-gray-700/50"
            >
              <div className="flex items-center gap-3 mb-4">
                <FiActivity className="w-6 h-6 text-purple-400" />
                <h2 className="text-xl font-semibold bg-gradient-to-r from-purple-400 to-pink-500 
                  text-transparent bg-clip-text">
                  Active Snipes
                </h2>
              </div>
              <SnipingCount />
            </motion.div>

            {/* System Status Card */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 shadow-xl 
                border border-gray-700/50"
            >
              <div className="flex items-center gap-3 mb-4">
                <FiServer className="w-6 h-6 text-blue-400" />
                <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-400 to-blue-500 
                  text-transparent bg-clip-text">
                  System Status
                </h2>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-3 h-3 bg-green-500 rounded-full animate-pulse" />
                <span className="text-gray-300 font-medium">
                  Active Users: {threadsData || '...'}
                </span>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  SniperIndexes: state.index.SniperIndexes,
  userName: state.profile.userName,
  CalendarIndexes: state.index.CalendarIndexes,
  year: state.profile.year,
  phone: state.profile.phone
});

export default connect(mapStateToProps, { appendIndex, removeIndex, threads })(Dashboard);
