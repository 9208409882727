import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './containers/Home';
import SearchCourses from './containers/SearchCourses.jsx';
import Profile from './containers/Profile';
import Dashboard from './containers/Dashboard';
import SearchResults from './containers/SearchResults';
import Layout from './hocs/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import { connect } from 'react-redux';
import Pricing from './containers/Pricing';
import { Toaster } from 'react-hot-toast';
import Statistics from './containers/Statistics';
import { FiInstagram, FiHeart } from 'react-icons/fi';
import { FaDiscord } from 'react-icons/fa';
import { DeveloperProvider } from './context/DeveloperContext';

const App = ({ isAuthenticated }) => {
  return (
    <DeveloperProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Toaster position="top-center" />
          <div className="flex-grow">
            <Routes>
              {/* Public route - Home */}
              <Route
                path="/"
                element={
                  <PublicRoute isAuthenticated={isAuthenticated}>
                    <Home />
                  </PublicRoute>
                }
              />

              {/* Layout route to wrap authenticated routes */}
              <Route path="/" element={<Layout />}>
                {/* Protected routes for authenticated users */}
                <Route
                  path="/search-courses"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <SearchCourses />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <SearchResults />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/upgrade"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Pricing />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/statistics"
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <Statistics />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </div>

          {/* Footer */}
          <footer className="bg-gradient-to-r from-gray-900 to-gray-800 border-t border-gray-800">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col items-center justify-center space-y-4">
                <div className="flex items-center space-x-6">
                  <a
                    href="https://www.instagram.com/ruturbo.official/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-gray-400 hover:text-pink-400 
                      transition-colors duration-200 group"
                  >
                    <FiInstagram className="w-5 h-5 group-hover:scale-110 transition-transform duration-200" />
                    <span className="font-medium">@ruturbo.official</span>
                  </a>
                  <a
                    href="https://discord.gg/a3kgc3x58D"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-gray-400 hover:text-indigo-400 
                      transition-colors duration-200 group"
                  >
                    <FaDiscord className="w-5 h-5 group-hover:scale-110 transition-transform duration-200" />
                    <span className="font-medium">Join our Discord</span>
                  </a>
                </div>
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <span>Made with</span>
                  <FiHeart className="w-4 h-4 text-red-500 animate-pulse" />
                  <span>for Rutgers Students</span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </DeveloperProvider>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
