import React, { createContext, useState, useContext } from 'react';

const DeveloperContext = createContext();

export const DeveloperProvider = ({ children }) => {
  const [isDeveloperMode, setIsDeveloperMode] = useState(false);

  const toggleDeveloperMode = () => {
    setIsDeveloperMode(prev => !prev);
  };

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      // Ctrl + Shift + D to toggle developer mode
      if (e.ctrlKey && e.shiftKey && e.key === 'D') {
        toggleDeveloperMode();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <DeveloperContext.Provider value={{ isDeveloperMode, toggleDeveloperMode }}>
      {children}
    </DeveloperContext.Provider>
  );
};

export const useDeveloperMode = () => useContext(DeveloperContext); 