import { setCookie, getCookie, clearAllCookies, doesCookieExist, removeCookie } from '../utils/cookies';

import axios from 'axios';
import {
    LOADING_TRUE,
    LOADING_FALSE,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGIN_PROFILE,
    LOGOUT,
    APPEND_INDEX,
    LOGOUT_PROFILE,
    REMOVE_INDEX,
    RESET_STATE,
    ADD_TO_CALENDAR,
    REMOVE_FROM_CALENDAR,
    CALENDAR_CONFLICT_ERROR,
    REQUEST_PROCESSING,
    REQUEST_COMPLETED
} from './types';
import toast from 'react-hot-toast';

// Login Action
export const login = (navigate) => async (dispatch) => {
    // Start loading
    // dispatch({ type: LOADING_TRUE });

    try {
        // Set a session ID (replace with actual login API call in production)
        setCookie("session_id", "fceba09b-fe17-49da-bb34-4b7328eae004", { expires: 24 });

        // Dispatch successful authentication
        dispatch({ type: AUTHENTICATED_SUCCESS });

        // Navigate to dashboard after successful login
        window.location.reload();
        navigate("/dashboard");
    } catch (err) {
        console.error('Error during login:', err);
        // dispatch({ type: AUTHENTICATED_FAIL });
    } 
};


// Logout Action
export const logout = (navigate) => async (dispatch) => {
    // Start loading
    dispatch({ type: LOADING_TRUE });

    try {
        // Clear the session cookie
        removeCookie("session_id");
        document.cookie = "session_id=; path=/; domain=ruturbo.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None";


        // Dispatch logout actions to update Redux state
        dispatch({ type: LOGOUT_PROFILE });
        dispatch({ type: LOGOUT });
        dispatch({type:RESET_STATE})

        // Add delay to keep the loading screen visible before navigating
        setTimeout(() => {
            // Navigate to the home page after logout
            navigate("/");

            // End loading once navigation is complete
            dispatch({ type: LOADING_FALSE });
        }, 500); // 10-second delay for the loading screen
        localStorage.clear();
    } catch (err) {
        console.error("Error during logout:", err);
        // Dispatch LOADING_FALSE in case of error to stop loading
        dispatch({ type: LOADING_FALSE });
        localStorage.clear();
    }
};




// Append Index Action
export const appendIndex = (indexes) => async (dispatch, getState) => {
    const currentState = getState();
    
    // Check if there's already a request in progress
    if (currentState.index.isProcessing) {
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Request in Progress</span>
                <span className="text-sm">Please wait for the current request to complete</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
        return false;
    }

    const currentSniperIndexes = currentState.index.SniperIndexes;

    // Check for duplicates
    const duplicateIndexes = indexes.filter(index =>
        currentSniperIndexes.some(item => item.sectionIndex === index)
    );

    if (duplicateIndexes.length > 0) {
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Already Sniping</span>
                <span className="text-sm">This course is already in your snipe list</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
        return false;
    }

    // Check sniper limit
    const currentSniperCount = currentSniperIndexes.length;
    const newIndexesCount = indexes.length;

    if (currentSniperCount + newIndexesCount > 7) {
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Sniper Limit Reached</span>
                <span className="text-sm">You can only snipe up to 7 courses at a time</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
        return false;
    }

    // Set processing state to true
    dispatch({ type: REQUEST_PROCESSING });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getCookie('session_id'),
        },
    };

    const body = JSON.stringify({
        email: currentState.profile.email,
        membership: currentState.profile.membership,
        index: indexes,
        phoneHooks: {
            phone: currentState.profile.phone,
            discord: currentState.profile.discord,
            telegram: currentState.profile.telegram
        }
    });

    // Show waiting notification
    const waitingToast = toast.loading(
        <div className="flex flex-col">
            <span className="text-lg font-bold">Processing Request</span>
            <span className="text-sm">Please wait while we process your request...</span>
        </div>,
        {
            position: 'top-center',
            style: {
                background: '#3B82F6',
                color: 'white',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
        }
    );

    try {
        // Call the API first
        const response = await axios.post('https://api.ruturbo.com/index', body, config);
        console.log("AppendIndex API Response:", response.data);

        if (response.status === 200) {
            const body2 = JSON.stringify({
                indexes: indexes
            });

            const res3 = await axios.post("https://api.ruturbo.com/get_course_data", body2, config);
            res3.data.forEach((course) => {
                dispatch({
                    type: APPEND_INDEX,
                    payload: course
                });
            });

            // Dismiss waiting toast and show success
            toast.dismiss(waitingToast);
            toast.success(
                <div className="flex flex-col">
                    <span className="text-lg font-bold">Success</span>
                    <span className="text-sm">Course successfully added to snipe list</span>
                </div>,
                {
                    duration: 4000,
                    position: 'top-center',
                }
            );
        }
    } catch (err) {
        console.error('Error in appendIndex:', err);
        // Dismiss waiting toast and show error
        toast.dismiss(waitingToast);
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Error</span>
                <span className="text-sm">Failed to add course to snipe list</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
    } finally {
        // Set processing state to false
        dispatch({ type: REQUEST_COMPLETED });
    }
};

export const removeIndex = (num) => async (dispatch, getState) => {
    const currentState = getState();
    
    // Check if there's already a request in progress
    if (currentState.index.isProcessing) {
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Request in Progress</span>
                <span className="text-sm">Please wait for the current request to complete</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
        return false;
    }

    // Set processing state to true
    dispatch({ type: REQUEST_PROCESSING });
    
    console.log("Removing index from Redux immediately", num);
    
    // Show waiting notification
    const waitingToast = toast.loading(
        <div className="flex flex-col">
            <span className="text-lg font-bold">Processing Request</span>
            <span className="text-sm">Please wait while we remove the course...</span>
        </div>,
        {
            position: 'top-center',
            style: {
                background: '#3B82F6',
                color: 'white',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
        }
    );

    // Dispatch Redux action to remove the item from the UI immediately
    dispatch({ type: REMOVE_INDEX, payload: num });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': getCookie("session_id")
        }
    };
    const body = JSON.stringify({
        email: currentState.profile.email,
        membership: currentState.profile.membership,
        index: [num] // pass the index as an array
    });

    try {
        console.log("Calling delete API");
        const response = await axios.post('https://api.ruturbo.com/deleteIndex', body, config);
        console.log("API Response:", response.data);
        
        // Dismiss waiting toast and show success
        toast.dismiss(waitingToast);
        toast.success(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Success</span>
                <span className="text-sm">Course successfully removed from snipe list</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
            }
        );
    } catch (err) {
        console.error('Error in RemoveIndex:', err);
        // Dismiss waiting toast and show error
        toast.dismiss(waitingToast);
        toast.error(
            <div className="flex flex-col">
                <span className="text-lg font-bold">Error</span>
                <span className="text-sm">Failed to remove course from snipe list</span>
            </div>,
            {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: '#EF4444',
                    color: 'white',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            }
        );
    } finally {
        // Set processing state to false
        dispatch({ type: REQUEST_COMPLETED });
    }
};

export const checkAuthStatus = (navigate, shouldNavigate = true) => async (dispatch, getState) => {
    try {
        const userName = getState().profile.userName;
        const isAuthenticated = getState().auth.isAuthenticated;

        if (doesCookieExist("session_id") && userName == null) {
            dispatch({ type: LOADING_TRUE });
            const session_id = getCookie("session_id");

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': session_id
                },
                withCredentials: true,
            };

            console.log("CALLING API");

            const res = await axios.get("https://api.ruturbo.com/getinfo", config);
            console.log("DATA: ", res.data);

            dispatch({ type: AUTHENTICATED_SUCCESS });
            dispatch({ type: LOGIN_PROFILE, payload: res.data });

            // Extract and dispatch indexes
            const extractedIndexes = res.data.index || [];
            const body2 = JSON.stringify({
                indexes: extractedIndexes
            })

            const res3 = await axios.post("https://api.ruturbo.com/get_course_data",body2, config);
            res3.data.forEach((index) => {
                dispatch({
                    type: APPEND_INDEX,
                    payload: index
                });
            });
            const extractedIndexesCalendar = res.data.Calendar_Index || [];
            const body = JSON.stringify({
                indexes: extractedIndexesCalendar
            })

            const res2 = await axios.post("https://api.ruturbo.com/get_course_data",body, config);
            console.log(" DATA FROM CALENDAR INDEXES : ",res2.data )
            res2.data.forEach((index) => {
                console.log("DATA IN WHILE: ",  index )
                dispatch({
                    type: ADD_TO_CALENDAR,
                    payload: index
                });
            });

            if (shouldNavigate) {
                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000);
            }

            dispatch({ type: LOADING_FALSE });
            return { session_id: getCookie("session_id"), userData: res.data };
        } else if (!doesCookieExist("session_id")) {
            dispatch({ type: LOGOUT });
            dispatch({ type: LOGOUT_PROFILE });
            localStorage.clear();
            navigate("/");
        }
    } catch (err) {
        console.error('Error during auth status check:', err);
        dispatch({ type: AUTHENTICATED_FAIL });
        localStorage.clear();
    }
};



export const threads = () => async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "adminUser123"
        },
        withCredentials: true,
    };

    try { 
        const res = await axios.get("https://api.ruturbo.com/threads", config);
        console.log("threads: ", res.data.ec2_response.Threads);
        return res.data.ec2_response.Threads; // Return just the Threads value
    } catch (err) {
        console.error('Error during getting threads:', err);
        return null;  // Return null in case of error to avoid rendering issues
    }
};






export const appendToCalendar = (data) => async (dispatch, getState) => {
  try {
    const { CalendarIndexes } = getState().index;
    
    // Check for duplicates first
    const isDuplicate = CalendarIndexes.some(
      existingCourse => existingCourse.sectionIndex === data.sectionIndex
    );

    // Check calendar limit
    const isCalendarFull = CalendarIndexes.length >= 10;

    // If calendar is full, show that message regardless of duplicate status
    if (isCalendarFull) {
      toast.error(
        <div className="flex flex-col">
          <span className="text-lg font-bold">Calendar Full</span>
          <span className="text-sm">You can only add up to 10 courses to your calendar</span>
        </div>,
        {
          duration: 4000,
          position: 'top-center',
          style: {
            background: '#EF4444',
            color: 'white',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        }
      );
      return false;
    }

    // If the section is already in calendar (and calendar isn't full)
    if (isDuplicate) {
      toast.error(
        <div className="flex flex-col">
          <span className="text-lg font-bold">Section Already Added</span>
          <span className="text-sm">This section is already in your calendar</span>
        </div>,
        {
          duration: 4000,
          position: 'top-center',
          style: {
            background: '#EF4444',
            color: 'white',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        }
      );
      return false;
    }

    const currentState = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: getCookie('session_id'),
      },
    };

    const body = JSON.stringify({
      email: currentState.profile.email,
      indexes: [data.sectionIndex]
    });

    // Make the API call first
    const res = await axios.post("https://api.ruturbo.com/add_calendar_index", body, config);
    
    // If API call is successful, add to Redux
    dispatch({
      type: ADD_TO_CALENDAR,
      payload: data
    });

    console.log('Added to Calendar:', data);
    return true;

  } catch (error) {
    console.error('Error adding to calendar:', error);
    dispatch({
      type: CALENDAR_CONFLICT_ERROR,
      payload: 'Error adding course to calendar'
    });
    return false;
  }
};

// Add new action to remove course from calendar
export const removeFromCalendar = (sectionIndex) => async (dispatch, getState) => {
    const currentState = getState();
    const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getCookie('session_id'), // Ensure `getCookie` is defined elsewhere
        },
      };
    try {
        const body = JSON.stringify({
            email: currentState.profile.email,
            indexes: [sectionIndex]
        })
        // Check if the index is in SniperIndexes
        const { SniperIndexes } = getState().index;
        const isBeingSniped = SniperIndexes.some(item => item.index === sectionIndex);

        // Only call delete API if the index is being sniped
        dispatch({
            type: REMOVE_FROM_CALENDAR,
            payload: sectionIndex
        });
        const res = await axios.post("https://api.ruturbo.com/delete_calendar_index", body, config);

        if (isBeingSniped) {
           
            // Remove from snipe list
            dispatch({
                type: REMOVE_INDEX,
                payload: sectionIndex
            });
            removeIndex(sectionIndex)
        }

        // Always remove from calendar
       
    } catch (err) {
        console.error('Error removing course:', err);
    }
};

export const addToCalendar = (course) => async (dispatch, getState) => {
  try {
    // Check if the course is already in CalendarIndexes
    const { CalendarIndexes } = getState().index;
    const isDuplicate = CalendarIndexes.some(
      existingCourse => existingCourse.sectionIndex === course.sectionIndex
    );

    if (isDuplicate) {
      dispatch({
        type: CALENDAR_CONFLICT_ERROR,
        payload: 'This section is already in your calendar'
      });
      return false;
    }

    // If not a duplicate, proceed with adding to calendar
    dispatch({
      type: ADD_TO_CALENDAR,
      payload: course
    });
    return true;

  } catch (error) {
    dispatch({
      type: CALENDAR_CONFLICT_ERROR,
      payload: 'Error adding course to calendar'
    });
    return false;
  }
};

export const clearCalendar = () => async (dispatch, getState) => {
  const currentState = getState();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getCookie('session_id'),
    },
  };

  try {
    const body = JSON.stringify({});

    // Call API to clear calendar
    dispatch({ type: 'CLEAR_CALENDAR' });
    
    await axios.post("https://api.ruturbo.com/clear_calendar", body, config);

    // Dispatch action to clear calendar in Redux
    

  } catch (error) {
    console.error('Error clearing calendar:', error);
  }
};