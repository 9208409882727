import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../index.css';
import { appendToCalendar } from '../actions/auth';
import { connect } from 'react-redux';
import { appendIndex } from '../actions/auth';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

const SearchResults = ({ appendIndex, appendToCalendar }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedCourses, setExpandedCourses] = useState({});
  const [openSections, setOpenSections] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const title = query.get('title');

  // Add calendar state from Redux
  const calendarIndexes = useSelector(state => state.index.CalendarIndexes);
  const MAX_CALENDAR_COURSES = 10;

  console.log('Current state:', {
    results: results,
    loading: loading,
    error: error,
    title: title
  });

  const dayMap = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    H: 'Thursday',
    F: 'Friday',
    S: 'Saturday',
    U: 'Sunday',
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    let hours = parseInt(time.slice(0, 2), 10);
    const minutes = time.slice(2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getFullDayName = (abbreviation) => {
    if (!abbreviation) return 'N/A';
    return abbreviation
      .split('')
      .map((day) => dayMap[day] || day)
      .join(', ');
  };

  const isAsyncSection = (meetingTimes) => {
    return meetingTimes.every(time => 
      !time.Meetingdays || time.Meetingdays === 'N/A' || 
      !time.StartTime || time.StartTime === 'N/A'
    );
  };

  const renderMeetingTimes = (section) => {
    if (isAsyncSection(section.meetingTimes)) {
      return (
        <div className="bg-purple-500/10 border border-purple-500/20 rounded-lg p-4 mt-4">
          <div className="flex items-center gap-2 text-purple-400 mb-2">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="font-medium">Asynchronous Class</span>
          </div>
          <p className="text-gray-400 text-sm">
            This section is fully online with no scheduled meeting times. 
            Students can complete coursework on their own schedule.
          </p>
        </div>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left text-gray-400 text-sm">
              <th className="py-2 px-4 font-medium">Days</th>
              <th className="py-2 px-4 font-medium">Time</th>
              <th className="py-2 px-4 font-medium">Location</th>
              <th className="py-2 px-4 font-medium">Campus</th>
            </tr>
          </thead>
          <tbody className="text-gray-300">
            {section.meetingTimes.map((time, timeIndex) => (
              !isAsyncSection([time]) && (
                <tr key={timeIndex} className="border-t border-gray-800">
                  <td className="py-2 px-4">{getFullDayName(time.Meetingdays)}</td>
                  <td className="py-2 px-4">
                    {formatTime(time.StartTime)} - {formatTime(time.EndTime)}
                  </td>
                  <td className="py-2 px-4">
                    {time.buildingCode} {time.roomnumber}
                  </td>
                  <td className="py-2 px-4">{time.Campus || 'N/A'}</td>
                </tr>
              )
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleAddSection = async (course, section) => {
    try {
      const formattedTimes = section.meetingTimes.map((time) => ({
        days: getFullDayName(time.Meetingdays),
        startTime: formatTime(time.StartTime),
        endTime: formatTime(time.EndTime),
      }));

      const courseData = {
        courseTitle: course.title,
        courseNumber: course.courseString,
        sectionNumber: section.number,
        sectionIndex: section.index,
        professor: section.instructorsText,
        meetingTimes: formattedTimes,
        isAsync: isAsyncSection(section.meetingTimes)
      };

      const result = await appendToCalendar(courseData);
      if (result) {
        toast.success('Successfully added to calendar');
      }
    } catch (error) {
      toast.error('Failed to add course to calendar');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!title || title.trim() === '') {
        setResults([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Clean up and prepare search terms
        const searchTerms = title.trim().toLowerCase().split(/\s+/);
        console.log('Search terms:', searchTerms);

        // Try searching with the full query first
        const fullQuery = encodeURIComponent(title.trim().toLowerCase());
        console.log('Trying full query:', fullQuery);
        const fullResponse = await axios.get(`https://api.ruturbo.com/search?title=${fullQuery}`);
        
        if (fullResponse.data?.results?.length > 0) {
          console.log('Found results with full query:', fullResponse.data.results);
          setResults(fullResponse.data.results);
        } 
        // If no results with full query, try with individual terms
        else if (searchTerms.length === 1 && searchTerms[0].length >= 3) {
          // For single words, try appending common terms
          const expandedQueries = [
            searchTerms[0],
            `${searchTerms[0]} structures`,
            `${searchTerms[0]} science`,
            `${searchTerms[0]} programming`
          ];

          let allResults = [];
          for (const query of expandedQueries) {
            console.log('Trying expanded query:', query);
            const response = await axios.get(`https://api.ruturbo.com/search?title=${encodeURIComponent(query)}`);
            if (response.data?.results) {
              allResults = [...allResults, ...response.data.results];
            }
          }

          // Remove duplicates based on courseString
          const uniqueResults = allResults.filter((course, index, self) =>
            index === self.findIndex((c) => c.courseString === course.courseString)
          );

          if (uniqueResults.length > 0) {
            console.log('Found results with expanded search:', uniqueResults);
            setResults(uniqueResults);
          } else {
            console.log('No results found with any query');
            setResults([]);
          }
        } else {
          console.log('No results found with full query');
          setResults([]);
        }

        // Fetch open sections
        const sectionsResponse = await axios.get('https://api.ruturbo.com/proxy/openSections');
        if (Array.isArray(sectionsResponse.data)) {
          setOpenSections(sectionsResponse.data);
        }
      } catch (err) {
        console.error('Search error:', err);
        if (err.response) {
          console.log('Error response:', err.response.data);
        }
        setError('Failed to fetch course data. Please try again.');
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [title]);

  // Helper function to parse sections
  const getSections = (sectionsStr) => {
    if (!sectionsStr || typeof sectionsStr !== 'string') {
      console.log('Invalid sections data:', sectionsStr);
      return [];
    }

    try {
      // Convert Python-style string to valid JSON
      const jsonStr = sectionsStr
        .replace(/'/g, '"')
        .replace(/None/g, 'null')
        .replace(/True/g, 'true')
        .replace(/False/g, 'false');

      console.log('Parsed JSON string:', jsonStr);
      const sections = JSON.parse(jsonStr);
      
      if (!Array.isArray(sections)) {
        console.log('Parsed result is not an array:', sections);
        return [];
      }

      console.log('Valid sections found:', sections.length);
      return sections.filter(section => {
        if (statusFilter === 'all') return true;
        const isOpen = openSections.includes(section.index);
        return statusFilter === 'open' ? isOpen : !isOpen;
      });
    } catch (error) {
      console.error('Error parsing sections:', error);
      return [];
    }
  };

  const toggleCourse = (index) => {
    setExpandedCourses(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const isSectionOpen = (index) => {
    return openSections.includes(index) ? 'open' : 'closed';
  };

  // Add progress bar component
  const CalendarProgress = () => {
    const progress = (calendarIndexes.length / MAX_CALENDAR_COURSES) * 100;
    const remaining = MAX_CALENDAR_COURSES - calendarIndexes.length;

    return (
      <div className="mb-6 bg-gray-900/30 backdrop-blur-lg rounded-lg p-3 border border-white/5">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <span className="text-base text-white/90">
              <span className="font-medium">{calendarIndexes.length}</span>
              <span className="text-white/40"> / {MAX_CALENDAR_COURSES}</span>
              <span className="text-white/60 ml-1.5">Courses in Calendar</span>
            </span>
          </div>
          <div className="text-white/60 text-sm">
            {remaining} {remaining === 1 ? 'Slot' : 'Slots'} Remaining
          </div>
        </div>
        <div className="relative h-1 bg-white/5 rounded-full overflow-hidden">
          <div 
            className={`absolute h-full rounded-full transition-all duration-500 ease-out ${
              progress >= 80 
                ? 'bg-gradient-to-r from-rose-500 to-rose-400' 
                : progress >= 50 
                  ? 'bg-gradient-to-r from-amber-500 to-amber-400'
                  : 'bg-gradient-to-r from-emerald-500 to-emerald-400'
            }`}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
        <div className="text-center space-y-8 animate-fade-in">
          {/* Animated Loading Circle */}
          <div className="relative w-24 h-24 mx-auto">
            {/* Outer spinning circle */}
            <div className="absolute inset-0 border-4 border-blue-500/20 rounded-full"></div>
            <div className="absolute inset-0 border-4 border-transparent border-t-blue-500 rounded-full 
              animate-smooth-spin"></div>
            
            {/* Inner pulsing circle */}
            <div className="absolute inset-4 bg-blue-500/20 rounded-full 
              animate-smooth-pulse"></div>
            
            {/* RU Logo */}
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-blue-400 text-xl font-bold">RU</span>
            </div>
          </div>

          {/* Loading Text */}
          <div className="space-y-3">
            <h3 className="text-xl font-semibold text-white">
              Searching Courses
            </h3>
            <div className="flex items-center justify-center gap-2">
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-smooth-bounce" 
                style={{ animationDelay: '0ms' }}></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-smooth-bounce" 
                style={{ animationDelay: '200ms' }}></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-smooth-bounce" 
                style={{ animationDelay: '400ms' }}></div>
            </div>
          </div>

          {/* Loading Progress Bar */}
          <div className="max-w-md mx-auto w-full bg-gray-800/50 rounded-full h-1.5 overflow-hidden">
            <div className="h-full bg-gradient-to-r from-blue-600 to-blue-400 rounded-full animate-progress"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-red-500/10 border border-red-500/20 rounded-xl p-6 text-center">
          <div className="w-16 h-16 mx-auto mb-4 text-red-500">
            <svg className="w-full h-full" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-white mb-2">Error Loading Courses</h3>
          <p className="text-red-400">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 
              transition-colors duration-200"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!results || results.length === 0) {
    console.log('No results to display:', { results, title });
    return (
      <div className="min-h-screen bg-gray-900 py-8 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-white text-lg">
            No courses found for "{title}"
          </div>
          <div className="text-gray-400 text-sm mt-2">
            Try searching with a different keyword or course number
          </div>
        </div>
      </div>
    );
  }

  console.log('Rendering results:', results);
  return (
    <div className="min-h-screen bg-gray-900 py-8 px-4">
      <div className="max-w-7xl mx-auto">
        <Toaster position="top-center" />
        
        {/* Add Calendar Progress Bar */}
        <CalendarProgress />

        {/* Search Bar */}
        <div className="mb-8">
          <form 
            onSubmit={(e) => {
              e.preventDefault();
              const searchInput = e.target.elements.search.value;
              if (searchInput.trim()) {
                window.location.href = `/search?title=${encodeURIComponent(searchInput.trim())}`;
              }
            }}
            className="flex gap-2"
          >
            <input
              type="text"
              name="search"
              defaultValue={title}
              placeholder="Search for courses..."
              className="flex-1 bg-gray-800 text-white px-4 py-3 rounded-lg border border-gray-700 
                focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                transition-all duration-200 placeholder-gray-500"
            />
            <button
              type="submit"
              className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                transition-colors duration-200 flex items-center gap-2"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              Search
            </button>
          </form>
        </div>

        {/* Filter Controls */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-white">
            Search Results for "{title}"
          </h2>
          
          <div className="flex items-center gap-4">
            <select
              id="status-filter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="bg-gray-800 text-white px-4 py-2 rounded-lg border border-gray-700
                focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 
                transition-all duration-200"
            >
              <option value="all">All Sections</option>
              <option value="open">Open Sections</option>
              <option value="closed">Closed Sections</option>
            </select>
          </div>
        </div>

        {/* Course List */}
        <ul className="space-y-4">
          {results.map((course, index) => {
            const sections = getSections(course.sections);
            
            return (
              <li key={index} className="bg-gray-800 rounded-xl overflow-hidden border border-gray-700">
                <div 
                  className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-700/50 
                    transition-all duration-200"
                  onClick={() => toggleCourse(index)}
                >
                  <div className="flex-1">
                    <div className="flex items-center space-x-4">
                      <span className="text-blue-400 font-mono font-medium">{course.courseString}</span>
                      <span className="text-white font-medium">{course.title}</span>
                      <span className="text-gray-400">{course.credits} credits</span>
                      <span className="bg-gray-700 text-gray-300 px-3 py-1 rounded-full text-sm">
                        {sections.length} section{sections.length !== 1 ? 's' : ''}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-gray-400 text-sm">
                      {expandedCourses[index] ? 'Hide Sections' : 'Show Sections'}
                    </span>
                    <svg 
                      className={`w-5 h-5 text-gray-400 transform transition-transform duration-200
                        ${expandedCourses[index] ? 'rotate-180' : ''}`}
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>

                {expandedCourses[index] && (
                  <div className="border-t border-gray-700 p-4 space-y-4">
                    {sections.map((section, sectionIndex) => (
                      <div 
                        key={sectionIndex} 
                        className="bg-gray-900/50 rounded-lg p-4 space-y-4 hover:bg-gray-900/70 
                          transition-colors duration-200"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-4">
                            <span className="text-white font-medium">
                              Section {section.number}
                            </span>
                            <span className="text-gray-400">
                              Index: {section.index}
                            </span>
                            <span className={`px-3 py-1 rounded-full text-sm
                              ${isSectionOpen(section.index) === 'open' 
                                ? 'bg-green-500/20 text-green-400' 
                                : 'bg-red-500/20 text-red-400'}`}
                            >
                              {isSectionOpen(section.index)}
                            </span>
                            {isAsyncSection(section.meetingTimes) && (
                              <span className="px-3 py-1 rounded-full text-sm
                                bg-purple-500/20 text-purple-400">
                                Async
                              </span>
                            )}
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddSection(course, section);
                            }}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                              transition-colors duration-200 text-sm font-medium"
                          >
                            Add Section
                          </button>
                        </div>

                        <div className="flex items-center gap-4 text-sm text-gray-400">
                          <div className="flex items-center gap-2">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                            <span>{section.instructorsText || 'TBA'}</span>
                          </div>
                        </div>

                        {renderMeetingTimes(section)}
                      </div>
                    ))}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { appendIndex, appendToCalendar })(SearchResults);
