import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { useDeveloperMode } from '../context/DeveloperContext';
import SearchResults from './SearchResults';

const SearchCourses = ({ appendIndex, appendToCalendar }) => {
  const { isDeveloperMode } = useDeveloperMode();
  const navigate = useNavigate();
  const [tapSequence, setTapSequence] = useState([]);
  const [lastTapTime, setLastTapTime] = useState(0);
  const correctSequence = ['top', 'top', 'bottom', 'bottom'];
  
  const handleTap = (position) => {
    const currentTime = new Date().getTime();
    
    // Reset sequence if too much time has passed (2 seconds)
    if (currentTime - lastTapTime > 2000) {
      setTapSequence([]);
    }
    
    setLastTapTime(currentTime);
    const newSequence = [...tapSequence, position];
    setTapSequence(newSequence);

    // Check if sequence matches
    if (newSequence.length === correctSequence.length) {
      if (JSON.stringify(newSequence) === JSON.stringify(correctSequence)) {
        localStorage.setItem('mobileAccess', 'true');
        window.location.reload();
      }
      setTapSequence([]);
    } else if (newSequence.length > correctSequence.length) {
      setTapSequence([]);
    }
  };

  const [formData, setFormData] = useState({
    keyword: '',
    subject: '',
    courseNumber: '',
    sectionNumber: '',
    professor: '',
    campus: 'NB',
    level: 'ALL',
    semester: 'FALL23',
    meetingDays: [],
    openSectionsOnly: true,
  });

  const campusOptions = [
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NK', label: 'Newark' },
    { value: 'CM', label: 'Camden' }
  ];

  const levelOptions = [
    { value: 'ALL', label: 'All Levels' },
    { value: 'UG', label: 'Undergraduate' },
    { value: 'G', label: 'Graduate' }
  ];

  const semesterOptions = [
    { value: 'FALL23', label: 'Fall 2023' },
    { value: 'SPRING24', label: 'Spring 2024' },
    { value: 'SUMMER24', label: 'Summer 2024' }
  ];

  const daysOfWeek = [
    { value: 'M', label: 'Monday' },
    { value: 'T', label: 'Tuesday' },
    { value: 'W', label: 'Wednesday' },
    { value: 'TH', label: 'Thursday' },
    { value: 'F', label: 'Friday' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDayToggle = (day) => {
    setFormData(prev => ({
      ...prev,
      meetingDays: prev.meetingDays.includes(day)
        ? prev.meetingDays.filter(d => d !== day)
        : [...prev.meetingDays, day]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchQuery = formData.keyword.trim();
    navigate(`/search?title=${encodeURIComponent(searchQuery)}`);
  };

  const handleReset = () => {
    setFormData({
      keyword: '',
      subject: '',
      courseNumber: '',
      sectionNumber: '',
      professor: '',
      campus: 'NB',
      level: 'ALL',
      semester: 'FALL23',
      meetingDays: [],
      openSectionsOnly: true,
    });
  };

  if (!isDeveloperMode && localStorage.getItem('mobileAccess') !== 'true') {
    return (
      <div className="min-h-screen bg-[#0A192F] bg-gradient-to-br from-[#0A192F] via-[#112A45] to-[#0A192F] flex items-center justify-center">
        <div 
          className="text-center h-full w-full flex flex-col justify-between py-20"
          style={{ touchAction: 'none' }}
        >
          <div 
            className="h-1/3 w-full"
            onTouchStart={() => handleTap('top')}
          />
          <div className="text-center">
            <div className="mb-8">
              <div className="text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 mb-4">
                Coming Soon
              </div>
              <div className="text-2xl text-blue-400/80 font-light">
                This feature is currently under development
              </div>
            </div>
            
            {/* Animated construction icon */}
            <div className="relative w-24 h-24 mx-auto">
              <div className="absolute inset-0 rounded-full border-4 border-blue-400/20 border-t-blue-400 animate-spin"></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <svg className="w-12 h-12 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
              </div>
            </div>

            <div className="mt-8 text-blue-400/60 text-sm">
              Check back later for updates
            </div>
          </div>
          <div 
            className="h-1/3 w-full"
            onTouchStart={() => handleTap('bottom')}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-8 px-4 sm:px-6 lg:px-8 relative">
      {/* Main Content */}
      <div className="max-w-4xl mx-auto">
        <div className="bg-gray-800 rounded-xl shadow-2xl p-6 space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white">Advanced Course Search</h2>
            <p className="mt-2 text-gray-400">Find the perfect courses for your schedule</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Basic Search Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* ... Your existing input fields ... */}
            </div>

            {/* Course Identifiers */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* ... Your existing course identifier fields ... */}
            </div>

            {/* Dropdowns */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* ... Your existing dropdown fields ... */}
            </div>

            {/* Meeting Days */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Meeting Days
              </label>
              <div className="flex flex-wrap gap-3">
                {daysOfWeek.map(day => (
                  <button
                    key={day.value}
                    type="button"
                    onClick={() => handleDayToggle(day.value)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                      ${formData.meetingDays.includes(day.value)
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
                  >
                    {day.label}
                  </button>
                ))}
              </div>
            </div>

            {/* Open Sections Toggle */}
            <div className="flex items-center">
              <input
                type="checkbox"
                name="openSectionsOnly"
                checked={formData.openSectionsOnly}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  openSectionsOnly: e.target.checked
                }))}
                className="h-4 w-4 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
              />
              <label className="ml-2 block text-sm text-gray-300">
                Show only open sections
              </label>
            </div>

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <button
                type="submit"
                className="flex-1 bg-gradient-to-r from-blue-500 to-blue-600 text-white py-2 px-4 
                  rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all duration-200"
              >
                Search Courses
              </button>
              <button
                type="button"
                onClick={handleReset}
                className="flex-1 bg-gray-700 text-gray-300 py-2 px-4 rounded-lg 
                  hover:bg-gray-600 transition-all duration-200"
              >
                Reset Form
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // Add any needed state mappings
});

export default connect(mapStateToProps)(SearchCourses); 