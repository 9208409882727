import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { removeIndex } from '../actions/auth';
import { FiX } from 'react-icons/fi';

const SnipingCount = ({ SniperIndexes, removeIndex }) => {
  const currentCount = Array.isArray(SniperIndexes) ? SniperIndexes.length : 0;
  const maxSnipes = 7;
  const percentage = (currentCount / maxSnipes) * 100;

  return (
    <div className="space-y-4">
      {/* Count Display */}
      <div className="flex items-center justify-between">
        <div className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-blue-500 
          text-transparent bg-clip-text">
          {currentCount}
          <span className="text-lg text-gray-400 ml-1">/ {maxSnipes}</span>
        </div>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className={`text-xs font-medium px-2 py-1 rounded-full 
            ${percentage >= 85 ? 'bg-red-500/20 text-red-400' : 
              percentage >= 50 ? 'bg-yellow-500/20 text-yellow-400' : 
              'bg-green-500/20 text-green-400'}`}
        >
          {percentage == 100 ?'Full':
           percentage >= 85 ? 'Almost Full' : 
           percentage >= 50 ? 'Moderate Usage' : 
           'Available Space'}
        </motion.div>
      </div>

      {/* Active Snipes List */}
      <div className="space-y-2 max-h-[200px] overflow-y-auto custom-scrollbar">
        {SniperIndexes.map((course, index) => (
          <motion.div
            key={course.sectionIndex}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
            className="bg-gray-700/50 rounded-lg p-2 flex items-center justify-between 
              group hover:bg-gray-700 transition-all duration-200"
          >
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-white truncate">
                {course.courseTitle}
              </p>
              <p className="text-xs text-gray-400">
                Section {course.sectionNumber} • Index {course.sectionIndex}
              </p>
            </div>
            <button
              onClick={() => removeIndex(course.sectionIndex)}
              className="ml-2 p-1 text-gray-400 hover:text-red-400 rounded-full 
                hover:bg-red-400/10 transition-all duration-200 opacity-0 group-hover:opacity-100"
            >
              <FiX className="w-4 h-4" />
            </button>
          </motion.div>
        ))}
        {currentCount === 0 && (
          <div className="text-center text-gray-400 text-sm py-2">
            No active snipes
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  SniperIndexes: state.index.SniperIndexes || []
});

export default connect(mapStateToProps, { removeIndex })(SnipingCount); 