import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDeveloperMode } from '../context/DeveloperContext';
import axios from 'axios';
import { getCookie } from '../utils/cookies';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Statistics = () => {
  const { isDeveloperMode } = useDeveloperMode();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [courseData, setCourseData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeUsers, setActiveUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [developerMode, setDeveloperMode] = useState(false);
  const [indexData, setIndexData] = useState(null);
  const [showAllProfessors, setShowAllProfessors] = useState(false);
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [professorChartType, setProfessorChartType] = useState('pie');
  const [courseChartType, setCourseChartType] = useState('pie');
  const coursesPerPage = 5;

  // Handle keyboard shortcut
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'D') {
        setDeveloperMode(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Fetch data effect
  useEffect(() => {
    if (!developerMode) return; // Only fetch if in developer mode

    const fetchData = async () => {
      try {
        setLoading(true);
        const session_id = getCookie("session_id");

        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': session_id
          },
          withCredentials: true,
        };

        // Fetch all indexes
        const indexResponse = await axios.get('https://api.ruturbo.com/get-all-indexes', config);
        console.log('Index Response:', indexResponse.data);
        
        // Flatten the array and remove nulls and empty arrays
        const flattenedIndexes = indexResponse.data.indexes
          .filter(group => Array.isArray(group) && group.length > 0)
          .flat()
          .sort((a, b) => a - b);
        
        setIndexData(flattenedIndexes);

        // Fetch course data for all indexes
        const body = JSON.stringify({ indexes: flattenedIndexes });
        const courseResponse = await axios.post('https://api.ruturbo.com/get_course_data', body, config);
        console.log('Raw Course Response:', courseResponse.data); // Debug log
        const processedData = courseResponse.data.map(course => {
          console.log('Processing course data:', course); // Debug log
          console.log('Original meeting times:', course.meetingTimes); // Debug log
          
          const processed = {
            ...course,
            meetingTimes: Array.isArray(course.meetingTimes) ? course.meetingTimes : []
          };
          
          console.log('Processed meeting times:', processed.meetingTimes); // Debug log
          return processed;
        });
        console.log('Course Response:', processedData);
        setCourseData(processedData);

        // Fetch active users directly from threads endpoint
        const threadsResponse = await axios.get('https://api.ruturbo.com/threads', {
          headers: {
            'Authorization': 'adminUser123'
          }
        });
        console.log('Threads Response:', threadsResponse.data);
        setActiveUsers(parseInt(threadsResponse.data.ec2_response.Threads));

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Set up interval to refresh active users count
    const interval = setInterval(async () => {
      try {
        const threadsResponse = await axios.get('https://api.ruturbo.com/threads', {
          headers: {
            'Authorization': 'adminUser123'
          }
        });
        setActiveUsers(parseInt(threadsResponse.data.ec2_response.Threads));
      } catch (err) {
        console.error('Error updating active users:', err);
      }
    }, 30000); // Update every 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [developerMode]); // Add developerMode as dependency

  const pieChartColors = [
    'rgba(56, 189, 248, 0.8)',   // Sky blue
    'rgba(34, 211, 238, 0.8)',   // Cyan
    'rgba(244, 114, 182, 0.8)',  // Pink
    'rgba(168, 85, 247, 0.8)',   // Purple
    'rgba(251, 146, 60, 0.8)',   // Orange
    'rgba(34, 197, 94, 0.8)',    // Green
    'rgba(234, 179, 8, 0.8)',    // Yellow
    'rgba(239, 68, 68, 0.8)',    // Red
    'rgba(99, 102, 241, 0.8)',   // Indigo
    'rgba(236, 72, 153, 0.8)',   // Hot pink
  ];

  const formatTime = (timeStr) => {
    return timeStr || 'N/A';
  };

  const processChartData = (courseData) => {
    if (!Array.isArray(courseData) || courseData.length === 0) return null;

    // Count courses per professor
    const professorCounts = courseData.reduce((acc, course) => {
      const professor = course.professor || 'TBA';
      acc[professor] = (acc[professor] || 0) + 1;
      return acc;
    }, {});

    // Count courses by title
    const courseTitleCounts = courseData.reduce((acc, course) => {
      const title = course.courseTitle || 'Unknown';
      acc[title] = (acc[title] || 0) + 1;
      return acc;
    }, {});

    // Sort professors by course count
    const sortedProfessors = Object.entries(professorCounts)
      .sort(([, a], [, b]) => b - a);

    // Sort courses by count
    const sortedCourses = Object.entries(courseTitleCounts)
      .sort(([, a], [, b]) => b - a);

    // Get either top 10 or all professors based on toggle
    const displayProfessors = showAllProfessors 
      ? sortedProfessors
      : sortedProfessors.slice(0, 10);

    const displayCourses = showAllCourses
      ? sortedCourses
      : sortedCourses.slice(0, 10);

    const professorData = {
      labels: displayProfessors.map(([name]) => name),
      datasets: [{
        data: displayProfessors.map(([, count]) => count),
        backgroundColor: displayProfessors.map((_, index) => 
          pieChartColors[index % pieChartColors.length]
        ),
      }]
    };

    const courseTitleData = {
      labels: displayCourses.map(([name]) => name),
      datasets: [{
        data: displayCourses.map(([, count]) => count),
        backgroundColor: displayCourses.map((_, index) => 
          pieChartColors[index % pieChartColors.length]
        ),
      }]
    };

    // Initialize days count with all days
    const daysCounts = {
      'Monday': 0,
      'Tuesday': 0,
      'Wednesday': 0,
      'Thursday': 0,
      'Friday': 0,
      'Saturday': 0,
      'Sunday': 0,
      'Async': 0
    };

    // Process days data
    courseData.forEach(course => {
      if (!course.meetingTimes || course.meetingTimes.length === 0) {
        daysCounts['Async']++;
        return;
      }

      let hasValidMeetingTime = false;
      course.meetingTimes.forEach(time => {
        if (time && time.days) {
          hasValidMeetingTime = true;
          // The day is already in full format (e.g., "Monday", "Tuesday")
          if (daysCounts.hasOwnProperty(time.days)) {
            daysCounts[time.days]++;
          }
        }
      });

      if (!hasValidMeetingTime) {
        daysCounts['Async']++;
      }
    });

    console.log('Final Days Counts:', daysCounts);

    // Make sure days are in chronological order
    const orderedDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Async'
    ];

    const daysData = {
      labels: orderedDays,
      datasets: [{
        data: orderedDays.map(day => daysCounts[day] || 0),
        backgroundColor: orderedDays.map(day => 
          day === 'Async' ? 'rgba(147, 51, 234, 0.8)' : 'rgba(56, 189, 248, 0.8)'
        ),
        borderWidth: 0,
        borderRadius: 4,
        borderSkipped: false,
        hoverBackgroundColor: orderedDays.map(day => 
          day === 'Async' ? 'rgba(147, 51, 234, 0.9)' : 'rgba(56, 189, 248, 0.9)'
        )
      }]
    };

    return {
      professorData,
      courseData: courseTitleData,
      daysData
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: 'rgb(229, 231, 235)',
          font: {
            size: 12,
            family: "'Inter', sans-serif",
            weight: '500'
          },
          padding: 25,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.9)',
        padding: 12,
        titleFont: {
          size: 14,
          family: "'Inter', sans-serif",
          weight: '600'
        },
        bodyFont: {
          size: 13,
          family: "'Inter', sans-serif"
        },
        borderColor: 'rgba(139, 92, 246, 0.1)',
        borderWidth: 1,
        displayColors: true,
        boxPadding: 6
      }
    },
    scales: {
      x: {
        ticks: {
          color: 'rgb(229, 231, 235)',
          font: {
            family: "'Inter', sans-serif",
            weight: '500'
          }
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        ticks: {
          color: 'rgb(229, 231, 235)',
          font: {
            family: "'Inter', sans-serif",
            weight: '500'
          }
        },
        grid: {
          color: 'rgba(139, 92, 246, 0.06)',
          drawBorder: false
        },
        border: {
          display: false
        }
      }
    }
  };

  // Filter courses based on search query
  const filteredCourses = courseData.filter(course => {
    if (!course) return false;
    const searchLower = searchQuery.toLowerCase();
    return (
      (course.sectionIndex?.toString().toLowerCase() || '').includes(searchLower) ||
      (course.courseTitle?.toLowerCase() || '').includes(searchLower) ||
      (course.professor?.toLowerCase() || '').includes(searchLower) ||
      (course.sectionNumber?.toLowerCase() || '').includes(searchLower)
    );
  });

  // Get current courses for pagination
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(indexOfFirstCourse, indexOfLastCourse);
  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!developerMode) {
    return (
      <div className="min-h-screen bg-[#0A192F] bg-gradient-to-br from-[#0A192F] via-[#112A45] to-[#0A192F] flex items-center justify-center">
        <div className="text-center">
          <div className="mb-8">
            <div className="text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 mb-4">
              Coming Soon
            </div>
            <div className="text-2xl text-blue-400/80 font-light">
              This feature is currently under development
            </div>
          </div>
          
          {/* Animated construction icon */}
          <div className="relative w-24 h-24 mx-auto">
            <div className="absolute inset-0 rounded-full border-4 border-blue-400/20 border-t-blue-400 animate-spin"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <svg className="w-12 h-12 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            </div>
          </div>

          <div className="mt-8 text-blue-400/60 text-sm">
            Check back later for updates
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0A192F] bg-gradient-to-br from-[#0A192F] via-[#112A45] to-[#0A192F] flex items-center justify-center">
        <div className="relative flex flex-col items-center">
          {/* Main spinning circle with RU logo */}
          <div className="relative w-40 h-40">
            {/* Outer spinning ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-400/20 border-t-blue-400 animate-spin"></div>
            {/* Middle spinning ring */}
            <div className="absolute inset-2 rounded-full border-4 border-blue-500/20 border-t-blue-500 animate-spin" style={{ animationDuration: '2s' }}></div>
            {/* Inner spinning ring */}
            <div className="absolute inset-4 rounded-full border-4 border-blue-600/20 border-t-blue-600 animate-spin" style={{ animationDuration: '3s' }}></div>
            
            {/* RU Logo */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="relative">
                <span className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 animate-pulse">
                  RU
                </span>
                {/* Glowing effect */}
                <div className="absolute inset-0 blur-lg bg-blue-400/20 animate-pulse"></div>
              </div>
            </div>
          </div>

          {/* Loading bar */}
          <div className="mt-8 w-64 h-2 bg-blue-900/30 rounded-full overflow-hidden">
            <div className="h-full bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 animate-loading-bar"></div>
          </div>

          {/* Loading text */}
          <div className="mt-4 text-lg font-semibold relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 animate-pulse">
              Loading Statistics
            </span>
            <span className="inline-flex ml-1">
              <span className="animate-loading-dot">.</span>
              <span className="animate-loading-dot" style={{ animationDelay: '0.2s' }}>.</span>
              <span className="animate-loading-dot" style={{ animationDelay: '0.4s' }}>.</span>
            </span>
          </div>
        </div>

        <style jsx>{`
          @keyframes loading-bar {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
          @keyframes loading-dot {
            0%, 20% { opacity: 0; }
            50% { opacity: 1; }
            100% { opacity: 0; }
          }
          .animate-loading-bar {
            animation: loading-bar 2s infinite;
          }
          .animate-loading-dot {
            animation: loading-dot 1.4s infinite;
            opacity: 0;
          }
        `}</style>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#0A192F] bg-gradient-to-br from-[#0A192F] via-[#112A45] to-[#0A192F] py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-red-500 text-xl">Error: {error}</div>
      </div>
    );
  }

  const chartData = processChartData(courseData);

  return (
    <div className="min-h-screen bg-[#0A192F] bg-gradient-to-br from-[#0A192F] via-[#112A45] to-[#0A192F] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-[95rem] mx-auto">
        <div className="flex flex-col items-center justify-center mb-12">
          <h1 className="text-4xl font-bold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-cyan-400 to-blue-400">
            Course Analytics Dashboard
          </h1>
          <p className="text-gray-400 text-center max-w-2xl">
            Comprehensive analysis and visualization of course data, enrollment patterns, and scheduling information
          </p>
        </div>

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            { title: 'Total Courses', value: courseData.length, icon: '📚' },
            { title: 'Unique Professors', value: new Set(courseData.map(c => c.professor)).size, icon: '👨‍🏫' },
            { title: 'Course Sections', value: new Set(courseData.map(c => c.sectionNumber)).size, icon: '📝' },
            { title: 'Total Time Slots', value: courseData.reduce((acc, curr) => acc + curr.meetingTimes.length, 0), icon: '⏰' }
          ].map((stat, index) => (
            <motion.div
              key={stat.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="bg-[#112240]/40 backdrop-blur-xl rounded-2xl p-6 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-400 text-sm font-medium mb-1">{stat.title}</p>
                  <p className="text-2xl font-bold text-white">{stat.value}</p>
                </div>
                <span className="text-2xl">{stat.icon}</span>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Charts Section */}
        {chartData && (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-8">
            {/* Active Users Stats */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-[#112240]/40 backdrop-blur-xl rounded-xl p-4 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300 relative overflow-hidden"
            >
              {/* Background Gradient */}
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-transparent to-purple-500/5 pointer-events-none" />
              
              <div className="flex flex-col h-full relative">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center space-x-3">
                    <div className="p-2.5 bg-blue-500/10 rounded-lg">
                      <svg 
                        className="w-5 h-5 text-blue-400"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </div>
                    <h2 className="text-base font-semibold text-white flex items-center">
                      Active Users
                    </h2>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="px-2 py-1 text-xs font-medium bg-green-500/10 text-green-400 rounded-full flex items-center">
                      <span className="w-1 h-1 bg-green-400 rounded-full mr-1"></span>
                      Live
                    </span>
                  </div>
                </div>

                <div className="flex-1 flex items-center justify-center py-6">
                  <div className="text-center">
                    <motion.div 
                      className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-cyan-400 to-blue-400"
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ 
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        delay: 0.1 
                      }}
                    >
                      {activeUsers}
                    </motion.div>
                    <motion.p 
                      className="text-blue-300/70 mt-2 text-sm"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      concurrent users
                    </motion.p>
                  </div>
                </div>

                <motion.div 
                  className="mt-4 pt-4 border-t border-blue-500/10"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="flex justify-between items-center text-xs">
                    <span className="text-blue-300/70">Real-time tracking</span>
                    <div className="flex items-center text-green-400">
                      <svg className="w-3 h-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                      <span>Active</span>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            {/* Professor Distribution */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-[#112240]/40 backdrop-blur-xl rounded-xl p-4 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-3">
                <h2 className="text-base font-semibold text-white flex items-center">
                  <span className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></span>
                  Professor Distribution
                </h2>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setProfessorChartType(professorChartType === 'pie' ? 'bar' : 'pie')}
                    className="text-xs px-2 py-0.5 rounded-full bg-blue-500/10 text-blue-300 hover:bg-blue-500/20 transition-all duration-200"
                  >
                    {professorChartType === 'pie' ? 'Bar' : 'Pie'}
                  </button>
                  <button
                    onClick={() => setShowAllProfessors(!showAllProfessors)}
                    className={`text-xs px-2 py-0.5 rounded-full transition-all duration-200 ${
                      showAllProfessors
                        ? 'bg-blue-500 text-white'
                        : 'bg-blue-500/10 text-blue-300 hover:bg-blue-500/20'
                    }`}
                  >
                    {showAllProfessors ? 'All' : 'Top 10'}
                  </button>
                </div>
              </div>
              <div className={`h-[240px] ${showAllProfessors ? 'flex' : ''}`}>
                {professorChartType === 'pie' ? (
                  <Pie 
                    data={{
                      ...chartData.professorData,
                      datasets: [{
                        ...chartData.professorData.datasets[0],
                        borderWidth: 0.5,
                        borderColor: 'rgba(10, 25, 47, 0.3)',
                        hoverBorderColor: 'rgba(17, 42, 69, 0.5)',
                        hoverBorderWidth: 1,
                        hoverOffset: 4
                      }]
                    }}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        ...chartOptions.plugins,
                        legend: {
                          ...chartOptions.plugins.legend,
                          display: true,
                          position: showAllProfessors ? 'right' : 'bottom',
                          align: 'start',
                          labels: {
                            ...chartOptions.plugins.legend.labels,
                            padding: 10,
                            boxWidth: 6,
                            boxHeight: 6,
                            color: 'rgba(255, 255, 255, 0.9)',
                            font: {
                              size: 10,
                              family: "'Inter', sans-serif",
                            },
                            generateLabels: (chart) => {
                              const data = chart.data;
                              if (data.labels.length && data.datasets.length) {
                                return data.labels.map((label, i) => ({
                                  text: `${label} (${data.datasets[0].data[i]})`,
                                  fillStyle: data.datasets[0].backgroundColor[i],
                                  strokeStyle: data.datasets[0].borderColor,
                                  lineWidth: data.datasets[0].borderWidth,
                                  hidden: false,
                                  index: i,
                                  fontColor: 'rgba(255, 255, 255, 0.9)'
                                }));
                              }
                              return [];
                            }
                          },
                          maxHeight: showAllProfessors ? 200 : undefined,
                          overflow: {
                            x: 'hidden',
                            y: 'auto'
                          }
                        }
                      },
                      layout: {
                        padding: {
                          right: showAllProfessors ? 80 : 0
                        }
                      }
                    }}
                  />
                ) : (
                  <Bar
                    data={{
                      ...chartData.professorData,
                      datasets: [{
                        ...chartData.professorData.datasets[0],
                        backgroundColor: chartData.professorData.datasets[0].backgroundColor.map(color => color.replace('0.8', '0.7')),
                        borderWidth: 0,
                        borderRadius: 4,
                        borderSkipped: false,
                        hoverBackgroundColor: chartData.professorData.datasets[0].backgroundColor,
                      }]
                    }}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: false,
                      responsive: true,
                      indexAxis: 'y',
                      plugins: {
                        ...chartOptions.plugins,
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        x: {
                          grid: {
                            color: 'rgba(255, 255, 255, 0.05)',
                            drawBorder: false,
                          },
                          ticks: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            font: {
                              family: "'Inter', sans-serif",
                              size: 10
                            }
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          },
                          ticks: {
                            color: 'rgba(255, 255, 255, 0.9)',
                            font: {
                              family: "'Inter', sans-serif",
                              size: 10
                            }
                          }
                        }
                      }
                    }}
                  />
                )}
              </div>
            </motion.div>

            {/* Course Distribution */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-[#112240]/40 backdrop-blur-xl rounded-xl p-4 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-3">
                <h2 className="text-base font-semibold text-white flex items-center">
                  <span className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></span>
                  Course Distribution
                </h2>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setCourseChartType(courseChartType === 'pie' ? 'bar' : 'pie')}
                    className="text-xs px-2 py-0.5 rounded-full bg-blue-500/10 text-blue-300 hover:bg-blue-500/20 transition-all duration-200"
                  >
                    {courseChartType === 'pie' ? 'Bar' : 'Pie'}
                  </button>
                  <button
                    onClick={() => setShowAllCourses(!showAllCourses)}
                    className={`text-xs px-2 py-0.5 rounded-full transition-all duration-200 ${
                      showAllCourses
                        ? 'bg-blue-500 text-white'
                        : 'bg-blue-500/10 text-blue-300 hover:bg-blue-500/20'
                    }`}
                  >
                    {showAllCourses ? 'All' : 'Top 10'}
                  </button>
                </div>
              </div>
              <div className={`h-[240px] ${showAllCourses ? 'flex' : ''}`}>
                {courseChartType === 'pie' ? (
                  <Pie 
                    data={{
                      ...chartData.courseData,
                      datasets: [{
                        ...chartData.courseData.datasets[0],
                        borderWidth: 0.5,
                        borderColor: 'rgba(10, 25, 47, 0.3)',
                        hoverBorderColor: 'rgba(17, 42, 69, 0.5)',
                        hoverBorderWidth: 1,
                        hoverOffset: 4
                      }]
                    }}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        ...chartOptions.plugins,
                        legend: {
                          ...chartOptions.plugins.legend,
                          display: true,
                          position: showAllCourses ? 'right' : 'bottom',
                          align: 'start',
                          labels: {
                            ...chartOptions.plugins.legend.labels,
                            padding: 10,
                            boxWidth: 6,
                            boxHeight: 6,
                            color: 'rgba(255, 255, 255, 0.9)',
                            font: {
                              size: 10,
                              family: "'Inter', sans-serif",
                            },
                            generateLabels: (chart) => {
                              const data = chart.data;
                              if (data.labels.length && data.datasets.length) {
                                return data.labels.map((label, i) => ({
                                  text: `${label} (${data.datasets[0].data[i]})`,
                                  fillStyle: data.datasets[0].backgroundColor[i],
                                  strokeStyle: data.datasets[0].borderColor,
                                  lineWidth: data.datasets[0].borderWidth,
                                  hidden: false,
                                  index: i,
                                  fontColor: 'rgba(255, 255, 255, 0.9)'
                                }));
                              }
                              return [];
                            }
                          },
                          maxHeight: showAllCourses ? 200 : undefined,
                          overflow: {
                            x: 'hidden',
                            y: 'auto'
                          }
                        }
                      },
                      layout: {
                        padding: {
                          right: showAllCourses ? 80 : 0
                        }
                      }
                    }}
                  />
                ) : (
                  <Bar
                    data={{
                      ...chartData.courseData,
                      datasets: [{
                        ...chartData.courseData.datasets[0],
                        backgroundColor: chartData.courseData.datasets[0].backgroundColor.map(color => color.replace('0.8', '0.7')),
                        borderWidth: 0,
                        borderRadius: 4,
                        borderSkipped: false,
                        hoverBackgroundColor: chartData.courseData.datasets[0].backgroundColor,
                      }]
                    }}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: false,
                      responsive: true,
                      indexAxis: 'y',
                      plugins: {
                        ...chartOptions.plugins,
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        x: {
                          grid: {
                            color: 'rgba(255, 255, 255, 0.05)',
                            drawBorder: false,
                          },
                          ticks: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            font: {
                              family: "'Inter', sans-serif",
                              size: 10
                            }
                          }
                        },
                        y: {
                          grid: {
                            display: false
                          },
                          ticks: {
                            color: 'rgba(255, 255, 255, 0.9)',
                            font: {
                              family: "'Inter', sans-serif",
                              size: 10
                            }
                          }
                        }
                      }
                    }}
                  />
                )}
              </div>
            </motion.div>

            {/* Weekly Distribution */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-[#112240]/40 backdrop-blur-xl rounded-xl p-4 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300"
            >
              <div className="flex items-center justify-between mb-3">
                <h2 className="text-base font-semibold text-white flex items-center">
                  <span className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></span>
                  Weekly Distribution
                </h2>
              </div>
              <div className="h-[240px]">
                <Bar 
                  data={chartData.daysData}
                  options={{
                    ...chartOptions,
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      ...chartOptions.plugins,
                      legend: {
                        display: false
                      },
                      tooltip: {
                        callbacks: {
                          label: function(context) {
                            return `${context.parsed.y} sections`;
                          }
                        }
                      }
                    },
                    scales: {
                      x: {
                        grid: {
                          color: 'rgba(255, 255, 255, 0.05)',
                          drawBorder: false,
                        },
                        ticks: {
                          color: 'rgba(255, 255, 255, 0.7)',
                          font: {
                            family: "'Inter', sans-serif",
                            size: 10
                          }
                        }
                      },
                      y: {
                        grid: {
                          color: 'rgba(255, 255, 255, 0.05)',
                          drawBorder: false,
                        },
                        ticks: {
                          color: 'rgba(255, 255, 255, 0.7)',
                          font: {
                            family: "'Inter', sans-serif",
                            size: 10
                          },
                          stepSize: 1
                        }
                      }
                    }
                  }}
                />
              </div>
            </motion.div>
          </div>
        )}
        
        {/* Course Information Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-[#112240]/40 backdrop-blur-xl rounded-xl p-4 border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300"
        >
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <h2 className="text-base font-semibold text-white flex items-center">
                  <span className="w-1.5 h-1.5 bg-blue-400 rounded-full mr-2"></span>
                  Detailed Course Information
                </h2>
                <span className="text-xs px-2 py-0.5 rounded-full bg-blue-500/10 text-blue-300">
                  {filteredCourses.length} courses
                </span>
              </div>
              <div className="relative flex-shrink-0">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setCurrentPage(1); // Reset to first page when searching
                  }}
                  placeholder="Search by course, professor, index..."
                  className="w-64 px-3 py-1.5 pl-9 text-sm bg-[#1E3A5F]/30 border border-blue-500/20 rounded-lg text-white placeholder-blue-300/50 focus:outline-none focus:border-blue-500/50 focus:ring-1 focus:ring-blue-500/25"
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-300/50">
                  <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="overflow-x-auto rounded-lg border border-blue-500/10">
              <table className="min-w-full divide-y divide-blue-500/10">
                <thead>
                  <tr className="bg-blue-500/5">
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Index</th>
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Course</th>
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Section</th>
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Professor</th>
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Days</th>
                    <th className="px-3 py-2.5 text-left text-xs font-medium text-blue-300/90 uppercase tracking-wider">Time</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-blue-500/10 bg-blue-500/[0.02]">
                  {currentCourses.map((course, index) => {
                    const isAsync = !course.meetingTimes?.length || 
                      course.meetingTimes.every(time => !time.days && !time.startTime && !time.endTime);

                    return (
                      <tr 
                        key={course.sectionIndex}
                        className={`text-xs hover:bg-blue-500/10 transition-all duration-150 ${
                          index % 2 === 0 ? 'bg-blue-500/[0.03]' : ''
                        }`}
                      >
                        <td className="px-3 py-2.5">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-500/10 text-blue-300">
                            {course.sectionIndex}
                          </span>
                        </td>
                        <td className="px-3 py-2.5">
                          <div className="flex flex-col">
                            <span className="text-white font-medium">{course.courseTitle}</span>
                            <span className="text-blue-300/70 text-xs mt-0.5">{course.courseNumber}</span>
                          </div>
                        </td>
                        <td className="px-3 py-2.5">
                          <span className="text-blue-300">{course.sectionNumber}</span>
                        </td>
                        <td className="px-3 py-2.5">
                          {course.professor ? (
                            <span className="text-blue-300">{course.professor}</span>
                          ) : (
                            <span className="text-blue-300/50 italic">TBA</span>
                          )}
                        </td>
                        <td className="px-3 py-2.5">
                          {isAsync ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-500/20 text-purple-300">
                              <span className="w-1 h-1 bg-purple-400 rounded-full mr-1.5"></span>
                              Async
                            </span>
                          ) : (
                            <span className="text-blue-300">
                              {course.meetingTimes.map((time, i) => (
                                <div key={i} className="flex items-center space-x-1">
                                  {time.days || (
                                    <span className="text-blue-300/50 italic">TBA</span>
                                  )}
                                </div>
                              ))}
                            </span>
                          )}
                        </td>
                        <td className="px-3 py-2.5">
                          {isAsync ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-500/20 text-purple-300">
                              <span className="w-1 h-1 bg-purple-400 rounded-full mr-1.5"></span>
                              Async
                            </span>
                          ) : (
                            <span className="text-blue-300">
                              {course.meetingTimes.map((time, i) => (
                                <div key={i}>
                                  {time.startTime && time.endTime ? (
                                    <span className="whitespace-nowrap">{time.startTime} - {time.endTime}</span>
                                  ) : (
                                    <span className="text-blue-300/50 italic">TBA</span>
                                  )}
                                </div>
                              ))}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex justify-between items-center mt-4 text-sm">
                <div className="text-blue-300/70">
                  Showing {indexOfFirstCourse + 1}-{Math.min(indexOfLastCourse, filteredCourses.length)} of {filteredCourses.length}
                </div>
                <div className="flex space-x-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                    <button
                      key={number}
                      onClick={() => paginate(number)}
                      className={`px-3 py-1 rounded-md transition-all duration-200 ${
                        currentPage === number
                          ? 'bg-blue-500 text-white'
                          : 'bg-blue-500/10 text-blue-300 hover:bg-blue-500/20'
                      }`}
                    >
                      {number}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {filteredCourses.length === 0 && (
              <div className="text-center py-8 text-blue-300/70">
                <svg className="mx-auto h-12 w-12 text-blue-300/30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="mt-4">No courses found matching your search.</p>
                <button 
                  onClick={() => {
                    setSearchQuery('');
                    setCurrentPage(1);
                  }}
                  className="mt-2 text-xs px-3 py-1 rounded-full bg-blue-500/10 text-blue-300 hover:bg-blue-500/20 transition-all duration-200"
                >
                  Clear Search
                </button>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Statistics;