import React from 'react';
import { motion } from 'framer-motion';
import { FiCheck, FiPhone, FiTarget, FiBell, FiClock } from 'react-icons/fi';
import { useDeveloperMode } from '../context/DeveloperContext';

const Pricing = () => {
  const { isDeveloperMode } = useDeveloperMode();

  const plans = [
    {
      name: 'Student',
      price: '29.99',
      description: 'Perfect for students who need basic course sniping',
      features: [
        { text: '10 Course Snipes', icon: <FiTarget className="text-blue-400" /> },
        { text: 'Phone Call Notifications', icon: <FiPhone className="text-green-400" /> },
        { text: 'Discord Notifications', icon: <FiBell className="text-purple-400" /> },
        { text: 'Real-time Updates', icon: <FiClock className="text-yellow-400" /> }
      ],
      color: 'from-blue-500 to-blue-600',
      hoverColor: 'from-blue-600 to-blue-700'
    },
    {
      name: 'Pro',
      price: '49.99',
      description: 'For power users who need maximum course sniping capability',
      features: [
        { text: '30 Course Snipes', icon: <FiTarget className="text-blue-400" /> },
        { text: 'Priority Phone Calls', icon: <FiPhone className="text-green-400" /> },
        { text: 'Priority Discord Alerts', icon: <FiBell className="text-purple-400" /> },
        { text: 'Instant Updates', icon: <FiClock className="text-yellow-400" /> }
      ],
      color: 'from-purple-500 to-purple-600',
      hoverColor: 'from-purple-600 to-purple-700',
      recommended: true
    }
  ];

  const handleUpgrade = async (planType) => {
    try {
      const response = await fetch('https://api.ruturbo.com/upgrade', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan: planType
        })
      });

      if (response.ok) {
        // Handle successful upgrade
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error('Error upgrading plan:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 py-8 px-4 sm:px-6 lg:px-8 relative">
      {!isDeveloperMode && (
        <div className="absolute inset-0 backdrop-blur-sm z-10 bg-gray-900/50 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-gradient-to-r from-gray-800 to-gray-900 p-8 rounded-2xl shadow-2xl 
              border border-gray-700/50 text-center"
          >
            <h2 className="text-3xl font-bold bg-gradient-to-r from-yellow-400 to-amber-500 
              text-transparent bg-clip-text mb-4">
              Pro Features Coming Soon
            </h2>
            <p className="text-gray-400 text-lg">
              We're working on exciting premium features to enhance your course sniping experience.
            </p>
          </motion.div>
        </div>
      )}

      <div className={`max-w-7xl mx-auto ${!isDeveloperMode ? 'filter blur-sm' : ''}`}>
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white">Choose Your Plan</h2>
          <p className="mt-4 text-xl text-gray-400">
            Select the perfect plan for your course sniping needs
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`relative bg-gray-800 rounded-2xl shadow-xl overflow-hidden
                ${plan.recommended ? 'ring-2 ring-purple-500' : ''}`}
            >
              {plan.recommended && (
                <div className="absolute top-0 right-0 bg-purple-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
                  Recommended
                </div>
              )}
              
              <div className="p-8">
                <h2 className="text-2xl font-bold text-white mb-2">{plan.name}</h2>
                <p className="text-gray-400 mb-6">{plan.description}</p>
                
                <div className="flex items-baseline mb-8">
                  <span className="text-4xl font-bold text-white">${plan.price}</span>
                  <span className="text-gray-400 ml-2">/Year</span>
                </div>

                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center space-x-3">
                      {feature.icon}
                      <span className="text-gray-300">{feature.text}</span>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => handleUpgrade(plan.name.toUpperCase())}
                  className={`w-full py-3 px-6 rounded-lg bg-gradient-to-r ${plan.color} 
                    hover:${plan.hoverColor} text-white font-semibold transition-all duration-200 
                    transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-offset-gray-800 focus:ring-${plan.color.split('-')[1]}`}
                >
                  Upgrade to {plan.name}
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="text-center mt-12">
          <p className="text-gray-400 text-sm">
            All plans include basic features like course tracking and email notifications
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pricing; 