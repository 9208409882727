import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, logout } from '../actions/auth';
import { getCookie } from '../utils/cookies';

const Navbar = ({ isAuthenticated, isLoading, login, logout, profile, userName }) => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = React.useRef(null);



  const handleLogin = () => {
    const loginUrl = "https://login.ruturbo.com/login?client_id=2d4gukv9tj3q93f6t67sptanbm&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapi.ruturbo.com%2Fcallback";
    window.location.href = loginUrl;
  };

  const handleLogin2 = async () => {

    await login(navigate);
    try {
      setSessionId(getCookie("session_id"));
      setUserData(userName);
    } catch (err) {
      console.log("session not there", err);
    }
  };

  const handleLogout = () => {
    logout(navigate);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="bg-gradient-to-r from-gray-900 to-gray-800 border-b border-gray-700 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo and Brand */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2 hover:opacity-90 transition-opacity">
              <span className="text-blue-400 text-2xl font-extrabold">RU</span>
              <span className="text-white text-2xl font-extrabold">Turbo</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          {isAuthenticated && (
            <div className="hidden md:flex space-x-4">
              <Link 
                to="/dashboard" 
                className="text-gray-300 hover:text-white px-4 py-2 rounded-lg text-sm font-medium
                  hover:bg-gray-700/50 transition-all duration-200"
              >
                Dashboard
              </Link>
              <Link 
                to="/search-courses" 
                className="text-gray-300 hover:text-white px-4 py-2 rounded-lg text-sm font-medium
                  hover:bg-gray-700/50 transition-all duration-200"
              >
                Search Courses
              </Link>
              <Link 
                to="/statistics" 
                className="text-gray-300 hover:text-white px-4 py-2 rounded-lg text-sm font-medium
                  hover:bg-gray-700/50 transition-all duration-200"
              >
                Statistics
              </Link>
            </div>
          )}

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-gray-400 hover:text-white p-2"
            >
              <svg 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                {isMobileMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>

          {/* Desktop Profile/Login */}
          <div className="hidden md:block">
            {isAuthenticated ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-3 text-gray-300 hover:text-white 
                    bg-gray-700/50 hover:bg-gray-600/50 px-4 py-2 rounded-lg transition-all duration-200
                    border border-gray-600 hover:border-gray-500"
                >
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 
                    flex items-center justify-center shadow-lg">
                    <span className="text-white font-semibold">
                      {userName ? userName[0].toUpperCase() : 'U'}
                    </span>
                  </div>
                  <span className="font-medium">{userName || 'User'}</span>
                  <svg
                    className={`w-5 h-5 transition-transform duration-200 ${
                      isDropdownOpen ? 'transform rotate-180' : ''
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>

                {/* Desktop Dropdown Menu */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-xl py-1 
                    border border-gray-700 backdrop-blur-sm bg-opacity-95 z-50">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-700/50
                        transition-all duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span>Profile</span>
                      </div>
                    </Link>
                    <div className="border-t border-gray-700 my-1"></div>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-red-400 hover:text-red-300 
                        hover:bg-gray-700/50 transition-all duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <span>Logout</span>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={handleLogin}
                className="text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 
                  hover:to-blue-700 px-6 py-2 rounded-lg text-sm font-medium transition-all duration-200
                  shadow-lg hover:shadow-blue-500/20 active:scale-95"
              >
                Login
              </button>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden border-t border-gray-700 py-3">
            {isAuthenticated ? (
              <>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  <Link
                    to="/dashboard"
                    className="block text-gray-300 hover:text-white hover:bg-gray-700/50 px-3 py-2 
                      rounded-md text-base font-medium transition-all duration-200"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Dashboard
                  </Link>
                  <Link
                    to="/search-courses"
                    className="block text-gray-300 hover:text-white hover:bg-gray-700/50 px-3 py-2 
                      rounded-md text-base font-medium transition-all duration-200"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Search Courses
                  </Link>
                  <Link
                    to="/profile"
                    className="block text-gray-300 hover:text-white hover:bg-gray-700/50 px-3 py-2 
                      rounded-md text-base font-medium transition-all duration-200"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Profile
                  </Link>
                  <Link
                    to="/statistics"
                    className="block text-gray-300 hover:text-white hover:bg-gray-700/50 px-3 py-2 
                      rounded-md text-base font-medium transition-all duration-200"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Statistics
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsMobileMenuOpen(false);
                    }}
                    className="w-full text-left text-red-400 hover:text-red-300 hover:bg-gray-700/50 
                      px-3 py-2 rounded-md text-base font-medium transition-all duration-200"
                  >
                    Logout
                  </button>
                </div>
              </>
            ) : (
              <div className="px-2 pt-2 pb-3">
                <button
                  onClick={() => {
                    handleLogin();
                    setIsMobileMenuOpen(false);
                  }}
                  className="w-full text-white bg-gradient-to-r from-blue-500 to-blue-600 
                    hover:from-blue-600 hover:to-blue-700 px-6 py-2 rounded-lg text-base 
                    font-medium transition-all duration-200"
                >
                  Login
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  profile: state.auth.profile,
  userName: state.profile.userName
});

export default connect(mapStateToProps, { login, logout })(Navbar);
