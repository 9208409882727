import {
  APPEND_INDEX,
  REMOVE_INDEX,
  REMOVE_FROM_CALENDAR,
  ADD_TO_CALENDAR,
  CLEAR_CALENDAR,
  CALENDAR_CONFLICT_ERROR,
  CLEAR_CALENDAR_ERROR,
  REQUEST_PROCESSING,
  REQUEST_COMPLETED
} from '../actions/types';

const initialState = {
  SniperIndexes: [],
  CalendarIndexes: [],
  calendarError: null,
  isProcessing: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case REQUEST_PROCESSING:
      return {
        ...state,
        isProcessing: true
      };

    case REQUEST_COMPLETED:
      return {
        ...state,
        isProcessing: false
      };

    case APPEND_INDEX: {
      // Check if the course is already in CalendarIndexes
      const isInCalendar = state.CalendarIndexes.some(
        course => course.sectionIndex === payload.sectionIndex
      );

      return {
        ...state,
        SniperIndexes: [...state.SniperIndexes, payload],
        // If not in calendar, add it
        CalendarIndexes: isInCalendar
          ? state.CalendarIndexes
          : [...state.CalendarIndexes, payload]
      };
    }

    case REMOVE_INDEX:
      return {
        ...state,
        SniperIndexes: state.SniperIndexes.filter(
          (obj) => obj.sectionIndex !== payload
        ), // Match with the usage in your Dashboard.js
      };

    case ADD_TO_CALENDAR: {
      // 1) Check if payload is already in CalendarIndexes
      const alreadyExists = state.CalendarIndexes.some(
        (course) => course.sectionIndex === payload.sectionIndex
      );
      // 2) If it exists, return the state as is—no duplication
      if (alreadyExists) {
        return state;
      }
      // 3) Otherwise, add the new course
      return {
        ...state,
        CalendarIndexes: [...state.CalendarIndexes, payload],
      };
    }

    case REMOVE_FROM_CALENDAR: {
      // If the course is being sniped, don't allow removal from calendar
      const isBeingSniped = state.SniperIndexes.some(
        course => course.sectionIndex === payload
      );

      return {
        ...state,
        CalendarIndexes: isBeingSniped
          ? state.CalendarIndexes
          : state.CalendarIndexes.filter(
              course => course.sectionIndex !== payload
            ),
      };
    }

    case CLEAR_CALENDAR:
      // Only clear courses that aren't being sniped
      return {
        ...state,
        CalendarIndexes: state.CalendarIndexes.filter(course =>
          state.SniperIndexes.some(
            snipedCourse => snipedCourse.sectionIndex === course.sectionIndex
          )
        )
      };

    default:
      return state;
  }
}
