import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiSearch, FiClock, FiBell, FiZap, FiShield, FiSmile, FiAlertCircle, FiUserX, FiPhone } from 'react-icons/fi';
import Navbar from '../components/Navbar';
import { connect } from 'react-redux';
import { login } from '../actions/auth';

const Home = ({ isAuthenticated, login }) => {
  const navigate = useNavigate();

  const handleAuthenticatedAction = async (destination) => {
    if (isAuthenticated) {
      navigate(destination);
    } else {
      const loginUrl = "https://login.ruturbo.com/login?client_id=2d4gukv9tj3q93f6t67sptanbm&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fapi.ruturbo.com%2Fcallback";
    window.location.href = loginUrl; 
    }
  };

  const features = [
    {
      icon: <FiSearch className="w-8 h-8 text-blue-400" />,
      title: 'Smart Course Search',
      description: 'Find your perfect courses with our advanced search system',
      color: 'from-blue-500/20 to-blue-600/20'
    },
    {
      icon: <FiClock className="w-8 h-8 text-green-400" />,
      title: 'Real-time Updates',
      description: 'Get instant notifications when course seats become available',
      color: 'from-green-500/20 to-green-600/20'
    },
    {
      icon: <FiBell className="w-8 h-8 text-purple-400" />,
      title: 'Custom Notifications',
      description: 'Choose how you want to be notified - Discord, Telegram, or SMS',
      color: 'from-purple-500/20 to-purple-600/20'
    },
    {
      icon: <FiZap className="w-8 h-8 text-yellow-400" />,
      title: 'Lightning Fast',
      description: 'Be the first to know about course openings with our rapid notification system',
      color: 'from-yellow-500/20 to-yellow-600/20'
    },
    {
      icon: <FiShield className="w-8 h-8 text-red-400" />,
      title: 'Secure & Reliable',
      description: 'Your data is safe with our enterprise-grade security',
      color: 'from-red-500/20 to-red-600/20'
    },
    {
      icon: <FiSmile className="w-8 h-8 text-teal-400" />,
      title: 'User Friendly',
      description: 'Intuitive interface designed for the best user experience',
      color: 'from-teal-500/20 to-teal-600/20'
    }
  ];

  const knownIssues = [
    {
      icon: <FiAlertCircle className="w-8 h-8 text-amber-400" />,
      title: 'Manual Login Required',
      description: 'Google sign-in is temporarily unavailable. Please use email registration for now. Email notifications are working as expected.',
      status: 'In Progress'
    },
    {
      icon: <FiUserX className="w-8 h-8 text-blue-400" />,
      title: 'Profile Updates Limited',
      description: "Profile information updates are coming soon. You'll be able to edit your details shortly.",
      status: 'In Development'
    },
    {
      icon: <FiPhone className="w-8 h-8 text-green-400" />,
      title: 'Additional Notifications',
      description: 'Discord, and Telegram notifications are being implemented. Phone notifications will work as soon as Profile editing as impelemented. Email notifications are fully functional.',
      status: 'In Development'
    }
  ];

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-24">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, ease: "easeOut" }}
              className="text-center relative z-10"
            >
              <h1 className="text-6xl md:text-7xl font-extrabold tracking-tight text-white mb-8 leading-tight">
                Get Your Dream{" "}
                <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-blue-500 text-transparent bg-clip-text">
                  Courses
                </span>
                <br className="hidden sm:block" />
                at Rutgers
              </h1>
              <p className="mt-6 text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto font-light leading-relaxed">
                Never miss a course opening again. RUTurbo helps you track your desired courses and notify you the moment they become available.
              </p>

              <div className="mt-12 flex flex-col sm:flex-row gap-6 justify-center items-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleAuthenticatedAction('/search-courses')}
                  className="w-full sm:w-auto px-10 py-5 bg-gradient-to-r from-blue-500 to-blue-600 text-white 
                    rounded-xl font-semibold text-lg shadow-xl hover:shadow-blue-500/30 
                    transition-all duration-300 transform hover:-translate-y-1"
                >
                  Start Searching
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleAuthenticatedAction('/dashboard')}
                  className="w-full sm:w-auto px-10 py-5 bg-gray-800/80 text-gray-300 rounded-xl font-semibold 
                    text-lg hover:bg-gray-700/80 transition-all duration-300 backdrop-blur-sm
                    transform hover:-translate-y-1 border border-gray-700/50 hover:border-gray-600"
                >
                  View Dashboard
                </motion.button>
              </div>
            </motion.div>
          </div>

          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute -top-1/2 left-1/2 transform -translate-x-1/2">
              <div className="w-[800px] h-[800px] bg-gradient-to-r from-blue-500/20 to-purple-500/20 
                rounded-full filter blur-3xl opacity-30 animate-pulse" />
            </div>
            <div className="absolute -bottom-1/2 right-0">
              <div className="w-[600px] h-[600px] bg-gradient-to-r from-purple-500/10 to-blue-500/10 
                rounded-full filter blur-3xl opacity-20 animate-pulse" style={{ animationDelay: '1s' }} />
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="relative py-24 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
              viewport={{ once: true }}
              className="text-center mb-20"
            >
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Everything you need to secure your courses
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Powerful features designed to make course registration effortless
              </p>
            </motion.div>

            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.2
                  }
                }
              }}
            >
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  variants={{
                    hidden: { y: 30, opacity: 0 },
                    visible: { y: 0, opacity: 1 }
                  }}
                  className={`p-8 rounded-2xl bg-gradient-to-br ${feature.color} 
                    backdrop-blur-lg border border-gray-700/50 hover:border-gray-500/50 
                    transition-all duration-500 hover:-translate-y-2 group
                    hover:shadow-lg`}
                >
                  <div className="bg-gray-800/50 rounded-xl p-3 w-fit mb-6 group-hover:scale-110 transition-transform duration-300">
                    {feature.icon}
                  </div>
                  <h3 className="text-2xl font-semibold text-white mb-4">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300 text-lg leading-relaxed">
                    {feature.description}
                  </p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="relative py-24 px-4 sm:px-6 lg:px-8">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-10">
              Ready to get started?
            </h2>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleAuthenticatedAction('/search-courses')}
              className="px-12 py-6 bg-gradient-to-r from-purple-600 to-blue-600 text-white 
                rounded-xl font-semibold text-xl shadow-xl hover:shadow-purple-500/30 
                transition-all duration-300 transform hover:-translate-y-1"
            >
              Start Searching Courses
            </motion.button>
          </motion.div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Home);
