import React, { useState } from 'react';
import { connect } from 'react-redux';
import { appendIndex, removeFromCalendar, clearCalendar } from '../actions/auth';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog } from '@headlessui/react';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';

const generatePastelColor = (str) => {
  // Return a default color if str is undefined or null
  if (!str) return 'hsl(210, 60%, 70%)';

  let hash = 0;
  const safeStr = String(str); // Convert to string to ensure we can use length and charCodeAt
  
  for (let i = 0; i < safeStr.length; i++) {
    hash = safeStr.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Generate more vibrant colors using HSL
  const h = Math.abs(hash % 360);
  const s = 60 + (Math.abs(hash) % 20); // Saturation between 60-80%
  const l = 65 + (Math.abs(hash) % 10); // Lightness between 65-75%
  return `hsl(${h}, ${s}%, ${l}%)`; // More vibrant version
};

// Create a color cache to store colors for course titles
const courseColorCache = new Map();

const ClearConfirmDialog = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
        {/* Overlay */}
        <div 
          className="fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity"
          onClick={onClose}
        />

        {/* Dialog Panel */}
        <div className="relative inline-block bg-gradient-to-b from-gray-800 to-gray-900 
          rounded-2xl text-left overflow-hidden shadow-xl transform transition-all 
          sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border border-gray-700"
        >
          <div className="px-6 pt-6 pb-8">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-semibold text-white flex items-center gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" 
                  className="h-6 w-6 text-red-500" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                  />
                </svg>
                Clear Schedule
              </h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-300 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            <p className="text-gray-300 text-base mb-8">
              This will remove all courses from your schedule. This action cannot be undone.
            </p>

            <div className="flex justify-end gap-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 
                  transition-all duration-200 flex items-center gap-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
                className="px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-white 
                  rounded-lg hover:from-red-600 hover:to-red-700 transition-all duration-200 
                  flex items-center gap-2 shadow-lg hover:shadow-red-500/20"
              >
                Clear Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Schedule = ({ CalendarIndexes, SniperIndexes, appendIndex, removeFromCalendar, clearCalendar }) => {
  const [expandedSlot, setExpandedSlot] = useState(null);
  const [hoveredBlock, setHoveredBlock] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [ref, bounds] = useMeasure();

  // Define time slots from 8 AM to 10 PM
  const timeSlots = [];
  for (let hour = 8; hour <= 22; hour++) {
    timeSlots.push(`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`);
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const timeToDecimal = (timeStr) => {
    const [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    return hours + (minutes / 60);
  };

  const CELL_HEIGHT = 50; // Base cell height

  const calculateBlockHeight = (startTime, endTime) => {
    const start = timeToDecimal(startTime);
    const end = timeToDecimal(endTime);
    const duration = end - start;
    
    // For a 1-hour block, we want it to exactly fill one cell
    const height = Math.max(duration * CELL_HEIGHT, CELL_HEIGHT);
    
    console.log(`Block from ${startTime} to ${endTime}: duration=${duration}, height=${height}px`);
    return `${height}px`;
  };

  const calculateTopOffset = (startTime, slotTime) => {
    const start = timeToDecimal(startTime);
    const slot = Math.floor(timeToDecimal(slotTime));
    
    // Align with the grid lines
    const offset = (start - slot) * CELL_HEIGHT;
    
    console.log(`Offset for ${startTime} in slot ${slotTime}: start=${start}, slot=${slot}, offset=${offset}px`);
    return `${offset}px`;
  };

  const isBeingSniped = (courseIndex) => {
    return SniperIndexes?.some(item => item.sectionIndex === courseIndex);
  };

  const handleSnipeClick = (e, courseIndex) => {
    e.stopPropagation();
    appendIndex([courseIndex]);
  };

  const handleRemove = (sectionIndex) => {
    console.log('Removing course with index:', sectionIndex);
    removeFromCalendar(sectionIndex);
  };

  const handleSlotMouseEnter = (slotId) => {
    setExpandedSlot(slotId);
  };

  const handleSlotMouseLeave = () => {
    if (!hoveredBlock) {
      setExpandedSlot(null);
    }
  };

  const handleBlockMouseEnter = (slotId, blockId) => {
    setExpandedSlot(slotId);
    setHoveredBlock(blockId);
  };

  const handleBlockMouseLeave = () => {
    setHoveredBlock(null);
  };

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { tension: 300, friction: 20 }
  });

  return (
    <animated.div style={springProps}>
      <div className="overflow-x-auto" ref={ref}>
        {/* Clear Schedule Button */}
        <motion.div 
          className="flex justify-end mb-4"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
        >
          <button
            onClick={() => setIsConfirmOpen(true)}
            className="px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg 
              transition-all duration-200 flex items-center space-x-2 hover:shadow-lg 
              hover:shadow-red-500/20 transform hover:-translate-y-0.5"
          >
            <motion.svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5"
              whileHover={{ rotate: 180 }}
              transition={{ duration: 0.3 }}
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </motion.svg>
            <span>Clear Schedule</span>
          </button>
        </motion.div>

        {/* Clear Confirmation Dialog */}
        <ClearConfirmDialog 
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={clearCalendar}
        />

        {/* Schedule Grid */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse min-w-[800px]">
            <thead>
              <tr>
                <th className="text-gray-400 text-sm font-semibold w-20 px-2">Time</th>
                {days.map(day => (
                  <th key={day} className="text-white font-bold text-center text-sm px-2 py-2 w-1/5">{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeSlots.map((timeSlot) => (
                <React.Fragment key={timeSlot}>
                  <tr>
                    <td className="text-gray-400 text-xs py-1 px-2 text-right h-[50px] border-t border-gray-700">
                      {timeSlot}
                    </td>
                    {days.map((day, dayIndex) => {
                      // Get all non-async courses for this day and time slot
                      const coursesInSlot = CalendarIndexes
                        .filter(course => !course.isAsync)
                        .flatMap(course => {
                          const meetings = course.meetingTimes.filter(m => m.days === day);
                          return meetings.map(meeting => ({
                            ...course,
                            currentMeeting: meeting
                          }));
                        }).filter(course => {
                          const startHour = Math.floor(timeToDecimal(course.currentMeeting.startTime));
                          const slotHour = Math.floor(timeToDecimal(timeSlot));
                          return startHour === slotHour;
                        });

                      const hasOverlap = coursesInSlot.length > 1;
                      const slotId = `${day}-${timeSlot}`;
                      const isExpanded = expandedSlot === slotId;

                      return (
                        <td 
                          key={slotId}
                          className={`border-t border-gray-700 relative ${isExpanded ? 'h-auto' : 'h-[50px]'} w-1/5 p-0.5`}
                          onMouseEnter={() => hasOverlap && handleSlotMouseEnter(slotId)}
                          onMouseLeave={handleSlotMouseLeave}
                        >
                          <div className={`absolute inset-1 ${isExpanded ? 'h-auto' : ''}`}>
                            <div className={`relative ${isExpanded ? 'space-y-2' : ''}`}>
                              {coursesInSlot.map((course, idx) => {
                                const meeting = course.currentMeeting;
                                const blockHeight = calculateBlockHeight(meeting.startTime, meeting.endTime);
                                const heightInPixels = parseFloat(blockHeight);
                                const fontSize = heightInPixels < 35 ? '0.65rem' : '0.75rem';
                                const tooltipPosition = dayIndex <= 1 ? 'right' : 'left';
                                const blockId = `${slotId}-${course.sectionIndex}`;
                                const isHovered = hoveredBlock === blockId;
                                const totalBlocks = coursesInSlot.length;
                                const overlapOffset = heightInPixels * 0.7; // Show 70% of each block

                                return (
                                  <motion.div
                                    key={course.sectionIndex}
                                    className="absolute w-full"
                                    initial={false}
                                    animate={{
                                      y: isExpanded ? idx * overlapOffset : 0,
                                      opacity: isExpanded ? 1 : totalBlocks > 1 ? 1 - (idx * 0.15) : 1,
                                      scale: isExpanded ? 1 : totalBlocks > 1 ? 1 - (idx * 0.05) : 1,
                                      zIndex: isExpanded ? (totalBlocks - idx) * 10 : isHovered ? 60 : totalBlocks - idx
                                    }}
                                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                                    onMouseEnter={() => {
                                      setHoveredBlock(blockId);
                                      if (hasOverlap) handleSlotMouseEnter(slotId);
                                    }}
                                    onMouseLeave={() => {
                                      setHoveredBlock(null);
                                      if (hasOverlap) handleSlotMouseLeave();
                                    }}
                                  >
                                    <div
                                      className={`rounded-md shadow-md overflow-hidden transition-all duration-200 
                                        hover:scale-[1.02] hover:shadow-lg mx-auto w-[98%] absolute ${
                                          isExpanded ? 'hover:translate-y-[-8px]' : ''
                                        }`}
                                      style={{
                                        height: blockHeight,
                                        top: calculateTopOffset(meeting.startTime, timeSlot),
                                        background: isBeingSniped(course.sectionIndex)
                                          ? 'linear-gradient(to right, rgb(22 163 74), rgb(34 197 94))'
                                          : `linear-gradient(to right, ${
                                              courseColorCache.get(course.courseTitle) || (() => {
                                                const baseColor = generatePastelColor(course.courseTitle);
                                                courseColorCache.set(course.courseTitle, baseColor);
                                                return baseColor;
                                              })()
                                            }, ${
                                              courseColorCache.get(course.courseTitle) || (() => {
                                                const baseColor = generatePastelColor(course.courseTitle);
                                                courseColorCache.set(course.courseTitle, baseColor);
                                                return baseColor;
                                              })()
                                            })`
                                      }}
                                    >
                                      <div className={`p-0.5 h-full flex flex-col justify-between
                                        ${isBeingSniped(course.sectionIndex) ? 'text-white' : 'text-gray-900'}`}
                                      >
                                        <div style={{ fontSize }}>
                                          <div className="font-bold leading-tight truncate">{course.courseTitle}</div>
                                          <div className="leading-tight truncate">{course.professor}</div>
                                          <div className="leading-tight text-[0.65rem] truncate">
                                            {`${meeting.startTime} - ${meeting.endTime}`}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Tooltip */}
                                    {isHovered && (
                                      <div className={`absolute 
                                        ${tooltipPosition === 'right' 
                                          ? 'left-[102%]' 
                                          : 'right-[102%]'} 
                                        top-0 bg-gray-900 text-white rounded-lg shadow-xl p-4 z-[100] w-80 
                                        border border-gray-700 transform transition-transform duration-200
                                        hover:scale-[1.02]`}
                                      >
                                        <div className={`absolute top-4 
                                          ${tooltipPosition === 'right'
                                            ? 'right-full border-r-gray-900'
                                            : 'left-full border-l-gray-900'}
                                          border-8 border-transparent`}
                                        />
                                        
                                        <div className="space-y-2">
                                          <div className="flex justify-between items-start gap-2">
                                            <h4 className="font-bold text-lg flex-1 break-words">{course.courseTitle}</h4>
                                            <button
                                              onClick={() => handleRemove(course.sectionIndex)}
                                              className="text-red-500 hover:text-red-400 p-1 rounded-full 
                                                hover:bg-red-500/10 transition-colors flex-shrink-0"
                                              type="button"
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                              </svg>
                                            </button>
                                          </div>
                                          <div className="space-y-1.5 text-sm">
                                            <p><span className="font-semibold text-gray-300">Professor:</span> <span className="text-white">{course.professor}</span></p>
                                            <p><span className="font-semibold text-gray-300">Section Number:</span> <span className="text-white">{course.sectionNumber}</span></p>
                                            <p><span className="font-semibold text-gray-300">Index:</span> <span className="text-white">{course.sectionIndex}</span></p>
                                            <p><span className="font-semibold text-gray-300">Time:</span> <span className="text-white">{`${meeting.startTime} - ${meeting.endTime}`}</span></p>
                                            <p><span className="font-semibold text-gray-300">Day:</span> <span className="text-white">{meeting.days}</span></p>
                                          </div>
                                          
                                          {isBeingSniped(course.sectionIndex) ? (
                                            <div className="mt-3 bg-green-500 text-white px-4 py-2 rounded-md 
                                              text-sm font-medium text-center cursor-default">
                                              Added to Snipe List ✓
                                            </div>
                                          ) : (
                                            <button
                                              onClick={(e) => handleSnipeClick(e, course.sectionIndex)}
                                              className="mt-3 w-full bg-blue-600 text-white px-4 py-2 rounded-md 
                                                hover:bg-blue-700 transition-colors text-sm font-semibold"
                                            >
                                              Add to Snipe List
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </motion.div>
                                );
                              })}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Async Classes Section */}
        {CalendarIndexes?.some(course => course?.isAsync) && (
          <motion.div
            className="mt-8 border-t border-gray-700 pt-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
              <svg className="w-5 h-5 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Asynchronous Courses
            </h3>
            
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {CalendarIndexes
                ?.filter(course => course?.isAsync)
                .map((course) => {
                  if (!course) return null;

                  const isSnipedClass = isBeingSniped(course.sectionIndex);
                  const courseString = course.courseNumber || course.courseString || 'Unknown Course';
                  const bgColor = courseColorCache.get(courseString) || 
                    generatePastelColor(courseString);
                  courseColorCache.set(courseString, bgColor);

                  return (
                    <motion.div
                      key={course.sectionIndex}
                      className="bg-gray-900/50 rounded-lg overflow-hidden border border-gray-700 
                        hover:border-gray-600 transition-all duration-200"
                      style={{
                        borderColor: `${bgColor}20`,
                        backgroundColor: `${bgColor}10`
                      }}
                      whileHover={{ scale: 1.02 }}
                      layout
                    >
                      <div className="p-4 space-y-3">
                        {/* Course Header */}
                        <div className="flex items-center justify-between">
                          <div>
                            <div className="flex items-center gap-2 mb-1">
                              <span className="text-sm font-mono text-gray-400">
                                {courseString}
                              </span>
                              <span className="px-2 py-0.5 bg-purple-500/20 text-purple-400 
                                rounded-full text-xs">
                                Async
                              </span>
                            </div>
                            <h4 className="text-white font-medium">{course.courseTitle || course.title || 'Untitled Course'}</h4>
                          </div>
                        </div>

                        {/* Course Details */}
                        <div className="text-sm text-gray-400 space-y-1">
                          <div className="flex items-center gap-2">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                            <span>
                              {course.professor ? (
                                <>Professor: {course.professor}</>
                              ) : (
                                <>Section {course.sectionNumber || 'N/A'}</>
                              )}
                            </span>
                          </div>
                          <div className="flex items-center gap-2">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                            </svg>
                            <span>Index: {course.sectionIndex}</span>
                          </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex items-center gap-2 pt-2">
                          {!isSnipedClass ? (
                            <button
                              onClick={(e) => handleSnipeClick(e, course.sectionIndex)}
                              className="flex-1 px-3 py-1.5 bg-blue-500 text-white rounded-lg 
                                hover:bg-blue-600 transition-colors duration-200 text-sm font-medium"
                            >
                              Add to Snipe List
                            </button>
                          ) : (
                            <div className="flex-1 px-3 py-1.5 bg-green-500 text-white rounded-lg 
                              text-sm font-medium text-center cursor-default transition-colors duration-200">
                              Added to Snipe List ✓
                            </div>
                          )}
                          <button
                            onClick={() => handleRemove(course.sectionIndex)}
                            className="px-3 py-1.5 bg-red-500/10 text-red-400 rounded-lg 
                              hover:bg-red-500/20 transition-colors duration-200 text-sm font-medium"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </div>
            </motion.div>
          )}
      </div>
    </animated.div>
  );
};

const mapStateToProps = (state) => ({
  CalendarIndexes: state.index.CalendarIndexes,
  SniperIndexes: state.index.SniperIndexes
});

export default connect(mapStateToProps, { 
  appendIndex, 
  removeFromCalendar,
  clearCalendar 
})(Schedule);
