import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FiMail, FiPhone, FiUser, FiCalendar, FiMessageCircle, FiEdit3, FiSave, FiBell, FiArrowUpCircle } from 'react-icons/fi';
import { FaDiscord, FaTelegram, FaGraduationCap, FaCrown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useDeveloperMode } from '../context/DeveloperContext';
import { setCookie, getCookie, clearAllCookies, doesCookieExist, removeCookie } from '../utils/cookies';
import { 
  SET_USERNAME, 
  SET_YEAR, 
  SET_TELEGRAM, 
  SET_PHONE, 
  SET_MEMBERSHIP, 
  SET_EMAIL, 
  SET_DISCORD,
  LOGIN_PROFILE 
} from '../actions/types';

const MembershipCard = ({ membership }) => (
  <div className="col-span-2 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center space-x-3">
        <FaCrown className="w-6 h-6 text-yellow-400" />
        <span className="text-gray-300 font-medium">Membership Status</span>
      </div>
    </div>
    <div className="flex items-center justify-between">
      <div>
        <p className="text-white font-medium text-lg">
          {membership === 'FT' ? 'Free Tier' : membership || 'Not set'}
        </p>
        <p className="text-sm text-gray-400 mt-1">
          {membership === 'FT' 
            ? 'Basic features and limited notifications' 
            : 'Advanced features and priority notifications'}
        </p>
      </div>
      {membership === 'FT' && (
        <button
          onClick={() => window.location.href = '/upgrade'}
          className="flex items-center space-x-2 bg-gradient-to-r from-yellow-500 to-yellow-600 
            hover:from-yellow-600 hover:to-yellow-700 text-white px-4 py-2 rounded-lg 
            transition-all duration-200 shadow-lg hover:shadow-yellow-500/20"
        >
          <FiArrowUpCircle className="w-5 h-5" />
          <span>Upgrade to Pro</span>
        </button>
      )}
    </div>
  </div>
);

const Profile = ({ userName, email, phone, year, telegram, discord, membership, ...props }) => {
  const { dispatch } = props;
  const [editMode, setEditMode] = useState({
    userName: false,
    phone: false,
    year: false,
    telegram: false,
    discord: false,
    membership: false
  });

  const [notifications, setNotifications] = useState({
    phone: false,
    telegram: false,
    discord: false
  });

  const [formData, setFormData] = useState({
    userName: userName || '',
    email: email || '',
    phone: phone || '',
    year: year || '',
    telegram: telegram || '',
    discord: discord || '',
    membership: membership || ''
  });

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      try {
        const response = await fetch('https://api.ruturbo.com/getNotifySettings');
        if (response.ok) {
          const data = await response.json();
          setNotifications({
            phone: data.phone || false,
            telegram: data.telegram || false,
            discord: data.discord || false
          });
        }
      } catch (error) {
        console.error('Error fetching notification settings:', error);
      }
    };

    fetchNotificationSettings();
  }, []);

  const handleEdit = (field) => {
    if (field !== 'email') {
      setEditMode(prev => ({
        ...prev,
        [field]: true
      }));
    }
  };

  const handleSave = async (field) => {
    if (field === 'email') {
      console.error('Email cannot be saved');
      return;
    }

    // Dispatch appropriate Redux action based on field
    const actionMap = {
      'userName': SET_USERNAME,
      'year': SET_YEAR,
      'telegram': SET_TELEGRAM,
      'phone': SET_PHONE,
      'discord': SET_DISCORD
    };

    // Map field names to API-specific attribute names
    const apiFieldMap = {
      'userName': 'username',
      'year': 'Year',
      'phone': 'Phone',
      'telegram': 'telegram',
      'discord': 'discord'
    };

    // Dispatch the corresponding action
    if (actionMap[field]) {
      dispatch({
        type: actionMap[field],
        payload: formData[field]
      });
    }

    try {
      const response = await fetch('https://api.ruturbo.com/updateinfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getCookie("session_id")
        },
        body: JSON.stringify({
          [apiFieldMap[field]]: formData[field]
        })
      });

      if (response.ok) {
        setFormData(prevData => ({
          ...prevData,
          [field]: formData[field]
        }));

        setEditMode(prev => ({
          ...prev,
          [field]: false
        }));
        
        // Dispatch login profile to update entire profile
        dispatch({ type: LOGIN_PROFILE });
      } else {
        const errorData = await response.json();
        console.error('Failed to update profile:', errorData);
        alert(`Failed to update ${field}: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(`Error updating ${field}. Please try again.`);
    }
  };

  const handleChange = (e, field) => {
    setFormData(prevFormData => ({ ...prevFormData, [field]: e.target.value }));
  };

  const handleNotificationChange = async (type) => {
    try {
      const response = await fetch('https://api.ruturbo.com/updateNotify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          notificationType: type,
          enabled: !notifications[type]
        })
      });

      if (response.ok) {
        setNotifications(prev => ({
          ...prev,
          [type]: !prev[type]
        }));
      }
    } catch (error) {
      console.error('Error updating notifications:', error);
    }
  };

  const yearOptions = [
    { value: 'Freshman', label: 'Freshman' },
    { value: 'Sophomore', label: 'Sophomore' },
    { value: 'Junior', label: 'Junior' },
    { value: 'Senior', label: 'Senior' }
  ];

  const membershipOptions = [
    { value: 'FREE', label: 'Free' },
    { value: 'BASIC', label: 'Basic' },
    { value: 'PRO', label: 'Pro' },
    { value: 'PREMIUM', label: 'Premium' }
  ];

  const ProfileField = ({ icon: Icon, label, field, value, brandIcon, options, hasNotification, isComingSoon }) => {
    const { isDeveloperMode } = useDeveloperMode();
    const BrandIcon = brandIcon;
    const iconColors = {
      userName: 'text-purple-400',
      email: 'text-green-400',
      phone: 'text-yellow-400',
      year: 'text-red-400',
      telegram: 'text-blue-400',
      discord: 'text-indigo-400',
      membership: 'text-amber-400'
    };

    const [inputValue, setInputValue] = useState(value);

    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (field === 'email') {
        console.error('Email cannot be saved');
        return;
      }

      // Dispatch appropriate Redux action based on field
      const actionMap = {
        'userName': SET_USERNAME,
        'year': SET_YEAR,
        'telegram': SET_TELEGRAM,
        'phone': SET_PHONE,
        'discord': SET_DISCORD
      };

      // Map field names to API-specific attribute names
      const apiFieldMap = {
        'userName': 'username',
        'year': 'Year',
        'phone': 'Phone',
        'telegram': 'telegram',
        'discord': 'discord'
      };

      // Dispatch the corresponding action
      if (actionMap[field]) {
        dispatch({
          type: actionMap[field],
          payload: inputValue
        });
      }

      try {
        const response = await fetch('https://api.ruturbo.com/updateinfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getCookie("session_id")
          },
          body: JSON.stringify({
            [apiFieldMap[field]]: inputValue
          })
        });

        if (response.ok) {
          setFormData(prevData => ({
            ...prevData,
            [field]: inputValue
          }));

          setEditMode(prev => ({
            ...prev,
            [field]: false
          }));
        } else {
          const errorData = await response.json();
          console.error('Failed to update profile:', errorData);
          alert(`Failed to update ${field}: ${errorData.message || 'Unknown error'}`);
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        alert(`Error updating ${field}. Please try again.`);
      }
    };

    const phoneNumberMessage = field === 'phone' && value !== 'N/A' && (
      <div className="bg-yellow-900/30 border border-yellow-700 rounded-lg p-3 mt-2 text-yellow-300 text-sm">
        <p className="mb-2">
          Save this number <span className="font-bold">+1(855)-694-6101</span> as Ru Turbo
        </p>
        <p className="text-xs text-yellow-400">
          Note: Snipes added before updating this phone number won't receive calls.
        </p>
      </div>
    );

    if (isComingSoon && !isDeveloperMode) {
      return (
        <div className="bg-gray-800 rounded-xl p-4 md:p-6 shadow-lg hover:shadow-xl transition-all duration-300 relative overflow-hidden">
          {/* Blurred Content */}
          <div className="filter blur-sm opacity-50">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                {brandIcon ? (
                  <BrandIcon className={`w-6 h-6 ${iconColors[field]}`} />
                ) : (
                  <Icon className={`w-6 h-6 ${iconColors[field]}`} />
                )}
                <h3 className="text-lg font-medium text-white">{label}</h3>
              </div>
            </div>
            <div className="text-gray-300 mt-1">
              {value || 'Not set'}
            </div>
          </div>
          
          {/* Coming Soon Overlay */}
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
            <div className="text-center">
              <h3 className="text-xl font-bold text-white mb-2">Coming Soon</h3>
              <p className="text-sm text-gray-300">Feature under development</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="bg-gray-800 rounded-xl p-4 md:p-6 shadow-lg hover:shadow-xl transition-all duration-300">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            {brandIcon ? (
              <BrandIcon className={`w-6 h-6 ${iconColors[field]}`} />
            ) : (
              <Icon className={`w-6 h-6 ${iconColors[field]}`} />
            )}
            <h3 className="text-lg font-medium text-white">{label}</h3>
          </div>
          <div className="flex items-center space-x-3">
            {hasNotification && (
              <div>
                <FiBell className={`w-4 h-4 ${notifications[field] ? 'text-blue-400' : 'text-gray-400'}`} />
              </div>
            )}
            {field !== 'email' && (editMode[field] ? (
              <form onSubmit={handleSubmit} className="w-full">
                {options && (
                  <select
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {options.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                )}
                {!options && (
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`Enter new ${label.toLowerCase()}`}
                  />
                )}
                <button 
                  type="submit"
                  className="mt-2 w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition-colors"
                >
                  Save
                </button>
              </form>
            ) : (
              <button
                onClick={() => handleEdit(field)}
                className="text-blue-400 hover:text-blue-300 transition-colors"
              >
                <FiEdit3 className="w-5 h-5" />
              </button>
            ))}
          </div>
        </div>
        {!editMode[field] && (
          <div className="text-gray-300 mt-1">
            {value || 'Not set'}
          </div>
        )}
        {phoneNumberMessage}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto space-y-8">
        <div className="text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-white">Profile Settings</h2>
          <p className="mt-2 text-sm md:text-base text-gray-400">Manage your account information</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          <div className="md:col-span-2">
            <MembershipCard membership={formData.membership} />
          </div>
          <ProfileField
            icon={FiUser}
            label="Username"
            field="userName"
            value={formData.userName}
          />
          <ProfileField
            icon={FiMail}
            label="Email"
            field="email"
            value={formData.email}
          />
          <ProfileField
            icon={FiPhone}
            label="Phone"
            field="phone"
            value={formData.phone}
            hasNotification
          />
          <ProfileField
            icon={FaGraduationCap}
            label="Year"
            field="year"
            value={formData.year}
            options={yearOptions}
          />
          <ProfileField
            label="Telegram"
            field="telegram"
            value={formData.telegram}
            brandIcon={FaTelegram}
            hasNotification
            isComingSoon={true}
          />
          <ProfileField
            label="Discord"
            field="discord"
            value={formData.discord}
            brandIcon={FaDiscord}
            hasNotification
            isComingSoon={true}
          />
        </div>

        <div className="mt-8 text-center text-xs md:text-sm text-gray-400">
          <p>Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userName: state.profile.userName,
  email: state.profile.email,
  phone: state.profile.phone,
  year: state.profile.year,
  telegram: state.profile.telegram,
  discord: state.profile.discord,
  membership: state.profile.membership
});

export default connect(mapStateToProps)(Profile);
